import type { JSX } from 'react';
import { ProductTypeKey } from '@wilm/common';
import type { Product, Variant } from '@wilm/common';
import BundleComponents from 'components/sales-link/organisms/content/products/bundle-attributes';
import Price from 'components/sales-link/organisms/content/products/price';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

export type SalesLinkAttribute = { label: string; value: string | JSX.Element; key: string };
interface UseSalesLinkProductReturn {
    isRenewable: (productTypeKey: ProductTypeKey, variant: Variant, currency: string) => boolean;
    isDelegate: (productTypeKey: ProductTypeKey, customerIsB2b: boolean) => boolean;
    getProductAttributesToShow: (
        productTypeKey: ProductTypeKey,
        variant: Variant,
        currency: string,
        showPrice?: boolean
    ) => SalesLinkAttribute[];
    getAdditionalProductVariantInfo: (product: Product, variant: Variant) => SalesLinkAttribute[];
    getTaxationAttributes: (Variant: Variant) => {
        availableInCountry: string;
        vatCalcItemClassification: string;
        la1TaxCountry: string;
        la1Id: string;
        la1TaxRegistrationNumber: string;
    };
}

const useSalesLinkProduct = (): UseSalesLinkProductReturn => {
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

    const isRenewable = (productTypeKey: ProductTypeKey, variant: Variant, currency: string) => {
        const isCentAmountGTZero = (variant.prices?.[currency]?.centAmount ?? 0) > 0;
        return !!(productTypeKey === ProductTypeKey.MEMBERSHIP && variant.attributes?.isRenewable && isCentAmountGTZero);
    };

    const isDelegate = (productTypeKey: ProductTypeKey, customerIsB2b: boolean) => {
        const delegateProductTypes = [ProductTypeKey.MEMBERSHIP, ProductTypeKey.TRAINING, ProductTypeKey.BUNDLE];
        return delegateProductTypes.includes(productTypeKey) && customerIsB2b;
    };

    const getTrainingAttributesToShow = (variant: Variant) => {
        return [
            {
                label: formatProductMessage({ id: 'shortDescription', defaultMessage: 'Short description' }),
                value: variant.attributes?.shortDescription ?? '-',
                key: 'shortDescription'
            },
            {
                label: formatProductMessage({ id: 'startDate', defaultMessage: 'Start Date' }),
                value: variant.attributes?.startDate ? formatDate(variant.attributes.startDate) : '-',
                key: 'startDate'
            },
            {
                label: formatProductMessage({ id: 'endDate', defaultMessage: 'End Date' }),
                value: variant.attributes?.endDate ? formatDate(variant.attributes.endDate) : '-',
                key: 'endDate'
            }
        ];
    };

    const getMembershipAttributesToShow = (variant: Variant, currency: string) => {
        return [
            {
                label: formatProductMessage({ id: 'membership.level', defaultMessage: 'Membership level' }),
                value: variant.attributes?.level ?? '-',
                key: 'level'
            },
            {
                label: formatProductMessage({ id: 'duration', defaultMessage: 'Duration' }),
                value: variant.attributes?.duration ? `${variant.attributes.duration} months` : '-',
                key: 'duration'
            },
            {
                label: formatProductMessage({ id: 'is.renewable', defaultMessage: 'Is renewable' }),
                value: isRenewable(ProductTypeKey.MEMBERSHIP, variant, currency) ? 'Yes' : 'No',
                key: 'isRenewable'
            }
        ];
    };

    const getBundleAttributesToShow = (variant: Variant) => {
        return [
            {
                label: formatProductMessage({ id: 'shortDescription', defaultMessage: 'Short description' }),
                value: variant.attributes?.shortDescription ?? '-',
                key: 'shortDescription'
            },
            {
                label: formatProductMessage({ id: 'duration', defaultMessage: 'Duration' }),
                value: variant.attributes?.duration ? `${variant.attributes.duration} months` : '-',
                key: 'duration'
            },
            {
                label: formatProductMessage({ id: 'components', defaultMessage: 'Components' }),
                value: <BundleComponents variant={variant} showName={true} showKey={false} />,
                key: 'bundleComponents'
            }
        ];
    };

    const getOtherAttributesToShow = (variant: Variant) => {
        return [
            {
                label: formatProductMessage({ id: 'sku', defaultMessage: 'SKU' }),
                value: variant.sku ?? '-',
                key: 'sku'
            },
            {
                label: formatProductMessage({ id: 'vat.clasification', defaultMessage: 'VAT classification' }),
                value: variant.attributes?.vatCalcItemClassification?.label ?? '-',
                key: 'vatCalcItemClassification'
            },
            {
                label: formatProductMessage({ id: 'is.limited.quantity', defaultMessage: 'Is limited quantity' }),
                value: variant.attributes?.isLimitedQuantity ? 'Yes' : 'No',
                key: 'isLimitedQuantity'
            }
        ];
    };

    const getTaxationAttributes = (variant: Variant) => {
        const taxationAttributes = {
            availableInCountry: variant.attributes?.availableInCountry?.key,
            vatCalcItemClassification: variant.attributes?.vatCalcItemClassification?.key,
            la1TaxCountry: variant.attributes?.la1TaxCountry,
            la1Id: variant.attributes?.la1Id,
            la1TaxRegistrationNumber: variant.attributes?.la1TaxRegistrationNumber
        };

        return taxationAttributes;
    };

    const getProductAttributesToShow = (productTypeKey: ProductTypeKey, variant: Variant, currency: string, showPrice = true) => {
        let attributes: SalesLinkAttribute[] = [];

        const discountedPrice =
            variant.discountedPrice && variant.discountedPrice.currencyCode === currency
                ? { ...variant.discountedPrice, type: 'centPrecision' }
                : undefined;

        switch (productTypeKey) {
            case ProductTypeKey.TRAINING:
                attributes = getTrainingAttributesToShow(variant);
                break;
            case ProductTypeKey.MEMBERSHIP:
                attributes = getMembershipAttributesToShow(variant, currency);
                break;

            case ProductTypeKey.BUNDLE:
                attributes = getBundleAttributesToShow(variant);
                break;

            case ProductTypeKey.PROFESSIONAL_SERVICE:
            case ProductTypeKey.OTHER:
                attributes = getOtherAttributesToShow(variant);
                break;
            default:
                attributes = [];
                break;
        }
        if (showPrice && currency) {
            const price = variant.prices?.[currency];
            let formatedPrice = price ? (
                <Price price={price} discountedPrice={discountedPrice} />
            ) : (
                <span className="text-sm text-red-500">
                    {formatProductMessage({
                        id: 'price.not.available.for.currency',
                        defaultMessage: 'Price in currency {currency} not available',
                        values: { currency }
                    })}
                </span>
            );
            if (productTypeKey === ProductTypeKey.BUNDLE) {
                formatedPrice = <span>-</span>;
            }

            attributes.push({
                label: 'Price',
                value: formatedPrice,
                key: 'price'
            });
        }
        return attributes;
    };

    const getAdditionalProductVariantInfo = (product: Product, variant: Variant) => {
        const info: SalesLinkAttribute[] = [
            {
                label: formatProductMessage({ id: 'product.name', defaultMessage: 'Product name' }),
                value: product.name ?? '-',
                key: 'name'
            },
            {
                label: formatProductMessage({ id: 'product.type', defaultMessage: 'Product type' }),
                value: product.productTypeName ?? '-',
                key: 'productTypeName'
            },
            {
                label: formatProductMessage({ id: 'product.type.name', defaultMessage: 'ICA {productTypeName}' }),
                value: variant.sku ?? '-',
                key: 'sku'
            },
            {
                label: formatProductMessage({ id: 'price', defaultMessage: 'Price' }),
                value: variant.price ? CurrencyHelpers.formatForCurrency(variant.price ?? 0) : '-',
                key: 'price'
            }
        ];

        for (const key in variant.attributes) {
            let value;

            if (key === 'bundleComponents') {
                value = <BundleComponents variant={variant} showName={true} showKey={true} />;
            } else {
                const attribute = variant.attributes[key];
                if (attribute && typeof attribute === 'object' && attribute.key && attribute?.label) {
                    value = attribute.label;
                } else {
                    value = JSON.stringify(variant.attributes[key]);
                }
            }

            info.push({
                label: key,
                value: value ?? '-',
                key
            });
        }

        return info;
    };

    return {
        isRenewable,
        isDelegate,
        getProductAttributesToShow,
        getAdditionalProductVariantInfo,
        getTaxationAttributes
    };
};

export default useSalesLinkProduct;
