'use client';

import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import type { Order } from '@wilm/common';
import ThankYouHeader from 'components/commercetools-ui/organisms/thank-you/components/thank-you-header';
import ThankYouLearners from 'components/commercetools-ui/organisms/thank-you/components/thank-you-learners';
import { useFormat } from 'helpers/hooks/useFormat';
import ThankYouOrderInfo from './order-info';
import OrderSummary from '../payment/summary';

const ThankYouContent: FC = () => {
    const router = useRouter();
    const [order, setOrder] = useState<Order>();
    const { formatMessage } = useFormat({ name: 'thank-you' });

    const removeLastPlacedOrder = () => {
        sessionStorage.removeItem('salesLinkLastPlacedOrder');
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const lastPlacedOrder = sessionStorage.getItem('salesLinkLastPlacedOrder');
            if (!lastPlacedOrder) {
                router.replace('/404');
            } else {
                setOrder(JSON.parse(lastPlacedOrder));
            }
            window.addEventListener('beforeunload', removeLastPlacedOrder);
        }
        return () => {
            window.removeEventListener('beforeunload', removeLastPlacedOrder);
        };
    }, []);

    if (!order) return <h1>Loading...</h1>;

    return (
        <div className="lg:gap-26 lg:bg-checkout-bg lg:px-40 lg:py-80">
            <div className="items-start lg:flex lg:gap-40">
                <div className="bg-white px-16 md:px-24 lg:w-full lg:rounded-md lg:py-36">
                    <ThankYouHeader email={order?.email} emailNotRecievedContent="" />
                    <ThankYouOrderInfo order={order} />
                    <ThankYouLearners learnerAssignmentLink={order?.learnerAssignmentLink} />
                </div>

                <OrderSummary
                    title={formatMessage({ id: 'order?.details', defaultMessage: 'Order details' })}
                    className="mb-20 bg-white px-16 max-lg:w-full md:mt-24 lg:mt-0 lg:w-2/5 lg:min-w-[470px]  lg:p-40 lg:py-36"
                    order={order}
                    payment={order?.payments?.[order?.payments?.length - 1]}
                    classNames={{
                        totalAmount: 'md:text-18 font-medium flex items-center justify-between'
                    }}
                    asSkeleton={!order?.orderId}
                />
            </div>
        </div>
    );
};

export default ThankYouContent;
