import React, { useEffect, useState } from 'react';
import { type PaymentLinkInfoType, type FieldErrors, InstalmentHelper } from '@wilm/common';
import Button from 'components/commercetools-ui/atoms/button';
import type { CheckboxProps } from 'components/commercetools-ui/atoms/checkbox';
import Link from 'components/commercetools-ui/atoms/link';
import type { TermsAndConditionsData } from 'components/commercetools-ui/organisms/checkout';
import Secure from 'components/commercetools-ui/organisms/checkout/components/secure';
import TermsAndConditions, {
    type TermsAndConditionsType
} from 'components/commercetools-ui/organisms/checkout/components/terms-and-conditions';
import CreditCardFields from 'components/cybersource/credit-cart-fields';
import Countdown from 'components/sales-link/atoms/countdown';
import SimpleAddress from 'components/sales-link/atoms/simple-address';
import PoNumberField from 'components/sales-link/organisms/payment/po-number';
import OrderSummary from 'components/sales-link/organisms/payment/summary';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useSessionStorage from 'helpers/hooks/useSessionStorage';
import { desktop } from 'helpers/utils/screensizes';
import { useCybersourceContext } from 'providers/cybersource';
import { usePaymentLinkContext } from 'providers/payment-link/payment';
import type { Reference } from 'types/reference';
import Address from './address';

declare global {
    interface Window {
        Flex?: any;
    }
}
export interface TermsAndConditionsAll {
    termsAndConditions: TermsAndConditionsData[];
    termsAndConditionsSecond: TermsAndConditionsData[];
    termsAndConditionsThird: TermsAndConditionsData[];
    contactUsLink: Reference;
}

export interface Props extends TermsAndConditionsAll {
    paymentLinkInfo: PaymentLinkInfoType;
}

const SalesLinkPayment: React.FC<Props> = ({
    paymentLinkInfo,
    termsAndConditions,
    termsAndConditionsSecond,
    termsAndConditionsThird,
    contactUsLink
}) => {
    const order = paymentLinkInfo.order;
    const { isInstalmentPayment, instalmentNumber, totalInstalments } = InstalmentHelper.getInstalmentsInfoForPayment(
        paymentLinkInfo.payment
    );
    const wordsOrdinalMap = [
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'ninth',
        'tenth',
        'eleventh',
        'twelfth'
    ];
    const instalmentWordOrdinal = isInstalmentPayment ? wordsOrdinalMap[instalmentNumber - 1] : '';
    const isBillingAddressEditable = true;
    const hasRequiredShipping = order?.lineItems?.some(lineItem => lineItem.variant?.attributes?.isShippingRequired);
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const [isDesktopSize] = useMediaQuery(desktop);

    const termsAndConditionsAll: TermsAndConditionsData[][] = [termsAndConditions, termsAndConditionsSecond, termsAndConditionsThird];
    const termsAndConditionsSkeleton: TermsAndConditionsType = {};

    for (const [key, terms] of Object.entries(termsAndConditionsAll)) {
        const termForRightCountry = terms?.find(el => el.country === order?.billingAddress?.country || el.country === 'default');
        if (termForRightCountry) {
            termsAndConditionsSkeleton[`termsAndConditions${key}`] = {
                text: termForRightCountry.markdownText,
                checked: false
            };
        }
    }
    const [termsAndConditionsMapped, setTermsAndConditionsMapped] = useSessionStorage(
        'termsAndConditionsMapped',
        termsAndConditionsSkeleton
    );

    const { setIsTermsAccepted, isPONumberRequired, cardFields, handlePayment, setCardField } = usePaymentLinkContext();

    const { setupMicroform } = useCybersourceContext();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [paymentIsLoading, setPaymentIsLoading] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState({} as FieldErrors);

    const handleCompletePurchase = async () => {
        setPaymentIsLoading(true);
        const handlePaymentResponse = await handlePayment();
        console.log('---> handlePaymentResponse', handlePaymentResponse);
        setPaymentIsLoading(false);

        if (handlePaymentResponse.isError && handlePaymentResponse.needsToStartFromBeginning) {
            console.log('---> Restart flow');

            return;
        }
        if (handlePaymentResponse.isError && handlePaymentResponse.fieldsErrors) {
            setFieldsErrors(handlePaymentResponse.fieldsErrors);
            return;
        }

        console.log('---> successful payment');
    };

    useEffect(() => {
        const signature = paymentLinkInfo.payment?.signature;
        if (signature) {
            setupMicroform(signature, paymentLinkInfo.payment?.clientLibrary, paymentLinkInfo.payment?.clientLibraryIntegrity);
        }
    }, [paymentLinkInfo.payment?.signature]);

    const handleTermsChange: CheckboxProps['onChange'] = ({ checked, name }) => {
        setTermsAndConditionsMapped({
            ...termsAndConditionsMapped,
            [name]: {
                ...termsAndConditionsMapped[name],
                checked
            }
        });
    };

    useEffect(() => {
        setIsTermsAccepted(Object.values(termsAndConditionsMapped).every(term => term.checked));
    }, [termsAndConditionsMapped, setIsTermsAccepted]);

    return (
        <div className="min-h-screen lg:bg-checkout-bg lg:pb-40">
            <div className="lg:px-40">
                <Secure />
                <div className=" mb-20 bg-white p-16 md:mt-20">
                    <h1 className="mb-10 text-20">Payment Link Page:</h1>
                    {!isInstalmentPayment && (
                        <p>
                            You are currently about to finalize payment for an order created by your account representative on behalf of
                            your customer profile.
                        </p>
                    )}
                    {isInstalmentPayment && (
                        <p>
                            You are currently about to finalize the {instalmentWordOrdinal} out of {totalInstalments} instalment payments
                            for an order created by your account representative on behalf of your customer profile.
                        </p>
                    )}
                    <p>Created by account representative: {paymentLinkInfo.requestedByEmail}</p>
                    <p>On behalf of: {paymentLinkInfo.customerEmail}</p>
                    <Countdown endDateTime={paymentLinkInfo.expiresAt ?? ''} />
                </div>
                <div className="flex flex-col items-start lg:flex-row lg:gap-40">
                    {/* Main content */}
                    <div className="mt-20 w-full  lg:mt-0 lg:grow lg:px-0">
                        <div className="bg-white p-16 md:p-30">
                            {isBillingAddressEditable && (
                                <Address
                                    address={order?.billingAddress}
                                    title={formatOrdersMessage({
                                        id: 'billing.address',
                                        defaultMessage: 'Billing address:'
                                    })}
                                    containerClassName="border border-checkout-step-border rounded-md p-16 text-md"
                                    wrapperClassName="border-checkout-border mb-30 border-b pb-16 lg:border-0 lg:pb-15"
                                />
                            )}
                            {!isBillingAddressEditable && (
                                <SimpleAddress
                                    address={order?.billingAddress}
                                    title={formatOrdersMessage({
                                        id: 'billing.address',
                                        defaultMessage: 'Billing address:'
                                    })}
                                    containerClassName="border-checkout-step-border p-0 text-md md:py-13 lg:border lg:p-15"
                                    wrapperClassName="border-checkout-border mb-30 border-b pb-16 lg:border-0 lg:pb-15"
                                />
                            )}
                            {hasRequiredShipping && (
                                <SimpleAddress
                                    address={order?.shippingAddress}
                                    title={formatOrdersMessage({
                                        id: 'shipping.address',
                                        defaultMessage: 'Shipping address:'
                                    })}
                                    containerClassName="border-checkout-step-border p-0 text-md md:py-13 lg:border lg:p-15"
                                    wrapperClassName="border-checkout-border mb-30 border-b pb-16 lg:border-0 lg:pb-15"
                                />
                            )}
                            {isPONumberRequired && <PoNumberField fieldsErrors={fieldsErrors} />}

                            <CreditCardFields
                                paymentIsLoading={paymentIsLoading}
                                cardFields={cardFields}
                                cardFieldsErrors={fieldsErrors}
                                setCardField={setCardField}
                            />
                        </div>

                        <div className="mt-30 hidden lg:block">
                            <TermsAndConditions termsAndConditions={termsAndConditionsMapped} handleTermsChange={handleTermsChange} />
                        </div>
                    </div>
                    {/* Sidebar */}

                    <OrderSummary
                        className="bg-white px-16 max-lg:w-full md:mt-24 lg:mt-0 lg:w-2/5 lg:min-w-[470px] lg:border-t-0 lg:p-40 lg:py-36"
                        order={order}
                        payment={paymentLinkInfo.payment}
                        title="Order summary"
                        classNames={{
                            totalAmount: 'md:text-18 font-medium flex items-center justify-between'
                        }}
                        asSkeleton={!order?.orderId}
                        button={
                            <>
                                {termsAndConditionsAll && !isDesktopSize && (
                                    <div className="mb-16 lg:hidden">
                                        <TermsAndConditions
                                            termsAndConditions={termsAndConditionsMapped}
                                            handleTermsChange={handleTermsChange}
                                        />
                                    </div>
                                )}
                                <Button
                                    variant="primary"
                                    disabled={false}
                                    className="w-full"
                                    type="submit"
                                    onClick={handleCompletePurchase}
                                >
                                    {formatCartMessage({ id: 'complete.purchase', defaultMessage: 'Complete purchase' })}
                                </Button>

                                <div className="mt-16 text-14">
                                    {formatCartMessage({
                                        id: 'payment.instalment.number.note',
                                        defaultMessage:
                                            'By selecting the Complete payment button you will finalize the {instalmentNumber} instalment payment out of {totalInstalments} instalments in total.',
                                        values: {
                                            instalmentNumber: instalmentNumber?.toString() ?? '',
                                            totalInstalments: totalInstalments?.toString() ?? ''
                                        }
                                    })}
                                </div>
                            </>
                        }
                    />
                </div>
                {contactUsLink && (
                    <div className="border-checkout-border fixed bottom-0 right-16 z-10 rounded-t-lg border bg-primary-black px-12 py-8 text-white lg:right-40">
                        <Link className="text-md font-bold" link={contactUsLink}>
                            Contact Us
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SalesLinkPayment;
