import React from 'react';

interface ProgressBarProps {
    progress?: number;
    height?: number;
    showLoadingText?: boolean;
    loadingText?: string;
    animate?: boolean;
    className?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
    progress = 0,
    height = 14,
    showLoadingText = true,
    loadingText = 'Loading...',
    animate = true,
    className = ''
}) => {
    return (
        <div className={`w-full ${className}`}>
            {showLoadingText && (
                <div className="mb-4 flex justify-between">
                    <span>{loadingText}</span>
                    <span>{progress}%</span>
                </div>
            )}
            <div className="w-full overflow-hidden rounded-full border border-learner-accent" style={{ height }}>
                <div
                    className={`h-full rounded-full bg-learner-accent ${animate ? 'transition-all duration-300 ease-in-out' : ''}`}
                    style={{
                        width: `${Math.min(Math.max(progress, 0), 100)}%`
                    }}
                />
            </div>
        </div>
    );
};

export default ProgressBar;
