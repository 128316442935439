export enum OriginEnum {
    FRONTEND = 'frontend',
    SALESFORCE = 'salesforce',
    SALES_LINK = 'sales-link',
    MIGRATED = 'migrated',
    UNKNOWN = 'unknown'
}
export enum CartInventoryItemErrorMessageEnum {
    OUT_OF_STOCK = 'outofstock',
    NOT_ENOUGH_AVAILABLE = 'notenoughavailable',
    MAX_QUANTITY_EXCEEDED = 'maxquantityexceeded'
}

export enum CartAddressType {
    BILLING = 'billing',
    SHIPPING = 'shipping'
}

export enum PaymentMethodType {
    CARD = 'card',
    DIRECT_DEBIT = 'directDebit',
    INVOICE = 'invoice',
    INSTALMENT = 'instalment',
    EMPTY = ''
}
