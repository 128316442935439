import type { FC } from 'react';
import React, { useContext, createContext } from 'react';
import type { LoginInfoSettings } from '@wilm/common';

interface LoginInfoSettingsProviderProps {
    loginInfoSettings: LoginInfoSettings;
    children: React.ReactNode;
}

const LoginInfoSettingsContext = createContext<LoginInfoSettings>({} as LoginInfoSettings);

const LoginInfoProvider: FC<LoginInfoSettingsProviderProps> = ({ loginInfoSettings, children }) => {
    return <LoginInfoSettingsContext.Provider value={loginInfoSettings}>{children}</LoginInfoSettingsContext.Provider>;
};

export default LoginInfoProvider;

export const useLoginInfoSettingsContext = () => useContext(LoginInfoSettingsContext);
