import type { FC } from 'react';
import React from 'react';
import NextLink from 'next/link';
import type { Product } from '@wilm/common';
import ArrowTileIcon from 'components/icons/arrow-tile';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';

interface ProductTileProps {
    product: Product;
}

const ProductTile: FC<ProductTileProps> = ({ product }) => {
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

    const selectedVariant = product?.variants[0];

    const { umbracoFrontendHost: host } = useBrandSettingsContext();

    const productUrl = host + product._url;

    return (
        <div className="relative">
            <NextLink href={productUrl}>
                <div className="group relative min-h-220 w-full rounded-tile bg-tile-primary py-26 pl-20 pr-26 transition-colors hover:bg-tile-accent xl:min-h-300 xl:py-18 xl:pl-38 xl:pr-32">
                    {product?.productTypeName && (
                        <div className="mb-45 flex items-center justify-between xl:mb-60">
                            <p className="font-tile text-15 font-tile-weight leading-tight text-tile-category group-hover:text-tile-primary xl:text-20">
                                {formatProductMessage({
                                    id: 'product.type.name',
                                    defaultMessage: 'ICA {productTypeName}',
                                    values: { productTypeName: product.productTypeName }
                                })}
                            </p>
                            <div className="relative size-44 flex-none rounded-full bg-tile-accent group-hover:bg-tile-primary xl:size-54">
                                <ArrowTileIcon className="absolute left-1/2 top-1/2 w-25  -translate-x-1/2 -translate-y-1/2  brightness-0  invert group-hover:filter-none lg:w-31" />
                            </div>
                        </div>
                    )}
                    <div>
                        <div className="font-tile-product-name font-tile-product-name-weight leading-[112%] group-hover:text-tile-primary xl:text-25">
                            {selectedVariant?.attributes?.displayName ?? product.name}
                        </div>
                    </div>
                </div>
            </NextLink>
        </div>
    );
};

export default ProductTile;
