import type { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';
import type { Money } from '@wilm/common';
import type { GTMTag, GTMActionPayload, TrackPurchasePayloadType } from './../interfaces/gtmTag';
import TagsActionType from './types/tagsActionType';
import getDiscountCodesString from '../utils/getDiscountCodesString';
import getGtmItems from '../utils/getGtmItems';
import getGtmValue from '../utils/getGtmValue';

const purchaseHandler = ({ payload }: { payload: GTMActionPayload }): GTMTag => {
    const purchasePayload = payload as TrackPurchasePayloadType;
    const order = purchasePayload.order;
    const orderDiscountCodes = getDiscountCodesString(purchasePayload?.discountCodes ?? []);
    const orderItems = getGtmItems(order?.lineItems ?? []);
    const orderValue = getGtmValue(order?.sum as Money);
    const orderCurrency = order?.sum.currencyCode as Currency;

    return {
        ecommerce: {
            currency: orderCurrency,
            transaction_id: order.orderId,
            items: orderItems,
            value: orderValue,
            ...(orderDiscountCodes && { coupon: orderDiscountCodes })
        },
        event: TagsActionType.PURCHASE,
        _clear: true
    };
};

export default purchaseHandler;
