'use client';

import type { Product } from '@wilm/common';
import type { ProductSliderProps } from 'components/commercetools-ui/organisms/product/product-slider';
import ProductSlider from 'components/commercetools-ui/organisms/product/product-slider';
import type { DataSource } from 'types/datasource';
import type { TasticProps } from 'frontastic/tastics/types';

function ProductSliderTastic({ data }: Readonly<TasticProps<DataSource<{ items: Product[] }> & ProductSliderProps>>) {
    if (!data?.data?.dataSource?.items) return <p>No products found.</p>;

    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mobile, tablet, desktop, ...props } = data;

    return <ProductSlider {...props} products={data.data.dataSource.items} />;
}

export default ProductSliderTastic;
