export enum AccountStatus {
    OPEN = 'open',
    PREPAYMENT_ONLY = 'prepayment',
    BLOCKED = 'blocked'
}

export enum AccountType {
    B2B = 'B2B',
    B2C = 'B2C'
}
