import useClassNames from 'helpers/hooks/useClassNames';
import type { ButtonProps, ButtonSize, ButtonVariant } from '.';

type variantBasedClassName = { [key in ButtonVariant]?: string };
type IncludesIcon = { includesIcon: boolean };
type UseButtonClassNames = (props: Pick<ButtonProps, 'variant' | 'size' | 'className' | 'loading' | 'asSkeleton'> & IncludesIcon) => string;

const useButtonClassNames: UseButtonClassNames = ({ variant, size, className = '', includesIcon, loading, asSkeleton = false }) => {
    const sizeClassNames: { [key in ButtonSize]: string } = {
        xs: 'p-8 text-sm rounded-md leading-tight',
        s: 'py-12 px-24 text-md font-regular leading-tight h-40',
        m: 'py-14 px-36',
        l: 'py-14 px-36',
        fit: 'p-0',
        full: 'py-14 w-full',
        icon: 'w-40 h-40 grid items-center justify-center'
    };

    const primaryClassName = useClassNames([
        'rounded-md bg-btn-primary-bg font-btnprimary text-btn-primary-size text-btn-primary-text outline-offset-0',
        'hover:bg-btn-primary-hover',
        'active:bg-btn-primary-bg active:shadow-button active:disabled:shadow-none',
        'focus-visible:border focus-visible:outline focus-visible:outline-offset-[3px]',
        'disabled:bg-btn-primary-dis'
    ]);
    const primaryCTAClassName = useClassNames([
        'rounded-xl bg-btn-primary-bg text-btn-lg-size font-btnprimary text-btn-primary-text outline-offset-0',
        'hover:bg-btn-primary-hover',
        'active:bg-btn-primary-bg active:shadow-button active:disabled:shadow-none',
        'focus-visible:border focus-visible:outline focus-visible:outline-offset-[3px]',
        'disabled:bg-btn-primary-dis'
    ]);

    const secondaryClassName = useClassNames([
        'rounded-lg border border-btn-secondary-border bg-btn-secondary-bg font-btnsecondary text-btn-secondary-size text-btn-secondary-text',
        'hover:shadow-bottom',
        'active:shadow-button active:outline-1 active:outline-2 active:outline-offset-0 active:outline-gray-300 active:disabled:shadow-none',
        'focus-visible:border-white-100 focus-visible:border focus-visible:outline focus-visible:outline-offset-[3px]',
        'disabled:border-btn-secondary-dis disabled:text-btn-secondary-dis'
    ]);

    const warningClassName = useClassNames([
        'rounded-md bg-red-500 font-btnprimary text-btn-primary-size text-btn-primary-text outline-offset-0 outline-offset-0',
        'hover:bg-red-600',
        'active:bg-red-500 active:shadow-button active:disabled:shadow-none',
        'focus-visible:border focus-visible:border-red-700 focus-visible:bg-red-700 focus-visible:outline focus-visible:outline-offset-[3px] focus-visible:outline-gray-700',
        'disabled:bg-neutral-400 disabled:text-neutral-100'
    ]);

    const underlineClassName = 'pb-2 text-gray-700 hover:underline disabled:text-neutral-400';

    const ghostClassName = 'disabled:text-neutral-400 focus-visible:outline-0';

    const variantClassNames: variantBasedClassName = {
        primary: primaryClassName,
        secondary: secondaryClassName,
        underlined: underlineClassName,
        ghost: ghostClassName,
        warning: warningClassName,
        primaryCta: primaryCTAClassName
    };

    const buttonClassName = useClassNames([
        sizeClassNames[size!],
        { 'flex items-center gap-10': includesIcon },
        { 'cursor-not-allowed': !!loading },
        'has-tooltip relative leading-4 transition',
        asSkeleton ? 'relative' : variantClassNames[variant!]!,
        className
    ]);

    return buttonClassName;
};

export default useButtonClassNames;
