import type { FC } from 'react';
import React, { useState, useCallback, useMemo } from 'react';
import { BrandLa1Map } from '@wilm/common';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Modal from 'components/commercetools-ui/atoms/modal';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useSalesLinkDataContext } from 'providers/sales-link/data';

export interface Props {
    isOpen: boolean;
    onSubmit: (currency: string, la1Id: string) => void;
}

const CurrencySelectorModal: FC<Props> = ({ isOpen, onSubmit }) => {
    const { currencies, currenciesLoading } = useSalesLinkDataContext();
    const { brandKey } = useBrandSettingsContext();

    const currenciesItems = useMemo(() => {
        const mappedCurrencies = currencies?.map(currency => ({ label: currency, value: currency }));
        return [{ label: 'Select currency', value: '' }, ...mappedCurrencies];
    }, [currencies]);

    const la1IdItems = useMemo(() => {
        const la1Ids = BrandLa1Map[brandKey] || [];
        return [{ label: 'Select LA1 Code', value: '' }, ...la1Ids.map(code => ({ label: code, value: code }))];
    }, [brandKey]);

    const [currency, setCurrency] = useState('');
    const [la1Id, setLa1Id] = useState('');
    const [currencyError, setCurrencyError] = useState('');
    const [la1idError, setLa1idError] = useState('');

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!currency) {
                setCurrencyError('Select a currency');
            } else if (!la1Id) {
                setLa1idError('Select a LA1 ID');
            } else {
                onSubmit(currency, la1Id);
            }
        },
        [currency, la1Id]
    );
    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '500px' } }}
            preventScroll={true}
        >
            <form autoComplete="off" onSubmit={e => handleSubmit(e)}>
                <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                    <p className="font-bold">Select currency and LA1 for your order</p>
                    <Dropdown
                        items={currenciesItems}
                        label="Currency"
                        onChange={e => setCurrency(e.target.value)}
                        value={currency}
                        required={true}
                        name="currency"
                        id="currency"
                        disabled={currenciesLoading}
                        error={!!currencyError}
                        errorMessage={currencyError}
                    />
                    <Dropdown
                        items={la1IdItems}
                        label="LA1 Code"
                        onChange={e => setLa1Id(e.target.value)}
                        value={la1Id}
                        required={true}
                        name="la1Id"
                        id="la1Id"
                        disabled={currenciesLoading}
                        error={!!la1idError}
                        errorMessage={la1idError}
                    />
                    <div className="flex justify-end gap-12">
                        <Button variant="primary" type="submit" className="rounded-md md:rounded-lg">
                            Confirm
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default CurrencySelectorModal;
