export enum PayNowErrorCodes {
    ORDER_ALREADY_PAID = 'ORDER_ALREADY_PAID',
    ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
    ORDER_NOT_PAID_BY_INVOICE = 'ORDER_NOT_PAID_BY_INVOICE',
    ORDER_CANCELLED = 'ORDER_CANCELLED',
    ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
    ORDER_WAITING_APPROVAL = 'ORDER_WAITING_APPROVAL',
    INVALID_ADDRESS = 'INVALID_ADDRESS',
    UNEXPECTED_ERROR = 'UNEXPECTED_ERROR'
}
