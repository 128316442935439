import type { FC } from 'react';
import { useEffect, useState, useMemo } from 'react';
import type { Order, PaymentMethodInfo } from '@wilm/common';
import { PaymentMethodType } from '@wilm/common';
import ccType from 'credit-card-type';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

type ThankYouOrderInfoProps = {
    order?: Order;
};

const ThankYouOrderInfo: FC<ThankYouOrderInfoProps> = ({ order }) => {
    const { formatMessage } = useFormat({ name: 'thank-you' });
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'orders' });
    const [loading, setLoading] = useState(true);
    const billingAddress = order?.billingAddress;
    const nameString = `${billingAddress?.firstName ?? ''} ${billingAddress?.lastName ?? ''},`;

    const billingAddressDetails = useMemo(() => {
        if (order?.billingAddress) {
            const { streetName, city, postalCode } = order.billingAddress;
            const label = `${streetName}, ${city}, ${postalCode}`;
            return label;
        }
    }, [order?.billingAddress]);

    useEffect(() => {
        if (order) {
            setLoading(false);
        }
    }, [order]);

    const paymentInfo: PaymentMethodInfo = useMemo(() => {
        const payment = order?.payments?.[order?.payments?.length - 1];
        const maskedCard = payment?.maskedCard;
        const lastDigits = maskedCard?.slice(-4);
        const creditCardType = (maskedCard && ccType(maskedCard)[0]?.type) ?? '';

        const paymentMethodToLabelMap: Record<string, PaymentMethodInfo> = {
            creditcard: {
                type: PaymentMethodType.CARD,
                label: maskedCard
                    ? `${creditCardType} ... ${lastDigits}`
                    : formatCheckoutMessage({ id: 'payment.label.my.card', defaultMessage: 'My Saved Card' })
            },
            creditcardwithpayerauthentication: {
                type: PaymentMethodType.CARD,
                label: maskedCard
                    ? `${creditCardType} ... ${lastDigits}`
                    : formatCheckoutMessage({ id: 'payment.label.my.card', defaultMessage: 'My Saved Card' })
            },
            invoice: {
                type: PaymentMethodType.INVOICE,
                label: formatCheckoutMessage({ id: 'payment.label.invoice', defaultMessage: 'Invoice' })
            },
            instalment: {
                type: PaymentMethodType.INSTALMENT,
                label: formatCheckoutMessage({ id: 'payment.label.instalment', defaultMessage: 'Instalment' })
            }
        };
        return paymentMethodToLabelMap[order?.custom?.fields?.paymentMethod?.toLowerCase() ?? payment?.paymentMethod ?? 'other'];
    }, [order?.payments, order?.custom?.fields?.paymentMethod]);

    return (
        <div className="grid gap-8 border-b border-checkout-border-color py-24 md:gap-0 lg:py-26" data-cy="order-info">
            <div className="flex flex-wrap gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography className="w-fit text-md leading-6 text-secondary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {formatMessage({ id: 'order.number', defaultMessage: 'Order number' }) + ':'}
                    </Typography>
                </div>
                <Typography
                    className="order-info-width text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8"
                    asSkeleton={loading}
                >
                    {order?.orderId}
                </Typography>
            </div>
            <div className="flex gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography
                        className="w-120 text-md leading-6 text-secondary-black md:w-136 md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'delivery', defaultMessage: 'Delivery' }) + ':'}
                    </Typography>
                </div>
                <div>
                    <Typography
                        className="order-info-width contents text-md leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'dear', defaultMessage: 'Dear' })}
                    </Typography>
                    <Typography
                        className="order-info-width contents text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {` ${nameString} `}
                    </Typography>
                    <Typography
                        className="order-info-width contents text-md leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({
                            id: 'inform.you',
                            defaultMessage: 'we would like to inform you that some products might take a few days for provisioning.'
                        })}
                    </Typography>
                </div>
            </div>
            <div className="flex gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography
                        className="w-120 text-md leading-6 text-secondary-black md:w-136 md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'payment', defaultMessage: 'Payment' }) + ':'}
                    </Typography>
                </div>
                <div className="flex flex-col gap-8 md:gap-0">
                    <Typography className="text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {paymentInfo?.type === PaymentMethodType.CARD
                            ? formatMessage({ id: 'credit.card', defaultMessage: 'Credit/debit card' })
                            : ''}
                    </Typography>
                    <Typography
                        className="text-md font-bold capitalize leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {`${paymentInfo?.label ?? ''}`}
                    </Typography>
                </div>
            </div>
            <div className="flex gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography
                        className="w-120 text-md leading-6 text-secondary-black md:w-136 md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'billing.address', defaultMessage: 'Billing address' }) + ':'}
                    </Typography>
                </div>
                <div className="flex flex-col gap-8 md:gap-0">
                    <Typography className="text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {nameString}
                    </Typography>
                    <Typography className="text-md leading-6 text-primary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {`${billingAddressDetails ?? billingAddressDetails}`}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ThankYouOrderInfo;
