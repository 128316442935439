import type { FC } from 'react';
import type { LineItem } from '@wilm/common';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useBundle from 'frontastic/hooks/useBundle';
import OrderItem from './order-item';

type OrderItemsListProps = {
    className?: string;
    lineItems: LineItem[];
};

const OrderItemsList: FC<OrderItemsListProps> = ({ lineItems, className }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const containerClassName = useClassNames(['grid w-full grid-cols-1 overflow-auto', className]);
    const { bundleComponents, totalItems } = useBundle(lineItems);
    const itemsLabel =
        totalItems === 1
            ? formatOrdersMessage({
                  id: 'item',
                  values: { total: totalItems },
                  defaultMessage: '{total} item'
              })
            : formatOrdersMessage({
                  id: 'items',
                  values: { total: totalItems },
                  defaultMessage: '{total} items'
              });

    return (
        <div className={containerClassName}>
            <div className="mb-6 mt-16 font-bold">{itemsLabel + ':'}</div>
            {lineItems.map(lineItem => (
                <OrderItem key={lineItem.lineItemId} lineItem={lineItem} bundleItems={bundleComponents?.[lineItem.lineItemId]} />
            ))}
        </div>
    );
};

export default OrderItemsList;
