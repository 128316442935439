import type { FC, ReactNode } from 'react';
import React, { useContext, createContext } from 'react';
import type { SessionSettingsResponseType } from '@wilm/common';
import cookie from 'js-cookie';
import i18nConfig from 'i18n.config';
import { sdk } from 'sdk';
import useSWR from 'swr';

interface SessionProviderProps {
    sessionSettings: SessionSettingsResponseType;
    children: ReactNode;
}

export type SessionContextShape = SessionSettingsResponseType;

const SessionContext = createContext<SessionContextShape>({} as SessionContextShape);

const SessionProvider: FC<SessionProviderProps> = ({ sessionSettings, children }) => {
    const locale = i18nConfig.defaultLocale;
    sdk.configureForNext(locale);
    const setUmbracoSessionInSessionData = async () => {
        if (!sessionSettings?.sessionCookieName || !sessionSettings?.sessionCookieDomain) {
            throw new Error('Session settings are not set!');
        }

        const umbracoSession = cookie.get(sessionSettings.sessionCookieName);

        const setUmbracoSessionInSessionData = await sdk.callAction({
            actionName: 'session/setUmbracoSessionInSessionData',
            payload: { umbracoSession }
        });

        if (setUmbracoSessionInSessionData.isError) {
            throw new Error(`Error when setting Umbraco session in sessionData ${setUmbracoSessionInSessionData.error.message}}`);
        }

        return setUmbracoSessionInSessionData.data;
    };

    const { isValidating, isLoading } = useSWR('session/setUmbracoSessionInSessionData', setUmbracoSessionInSessionData, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });

    const sessionReady = !isValidating && !isLoading;

    return <SessionContext.Provider value={sessionSettings}>{sessionReady ? children : null}</SessionContext.Provider>;
};

export default SessionProvider;

export const useSession = () => useContext(SessionContext);
