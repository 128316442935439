import type { FC } from 'react';
import React from 'react';
import type { Product } from '@wilm/common';
import type { SwiperOptions } from 'swiper';
import Slider from 'components/commercetools-ui/atoms/slider';
import type { SubtitleProps } from 'components/commercetools-ui/atoms/subtitle';
import Subtitle from 'components/commercetools-ui/atoms/subtitle';
import Typography from 'components/commercetools-ui/atoms/typography';
import Wrapper from 'components/HOC/wrapper';
import useClassNames from 'helpers/hooks/useClassNames';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import type { Reference } from 'types/reference';
import Link from '../../content/link';
import Tile from '../product-tile';

export interface ProductSliderProps extends Partial<SwiperOptions> {
    products: Product[];
    title: string;
    titleVariant?: 'xs' | 'sm' | 'lg';
    subline?: string;
    subtitleVariant?: SubtitleProps['variant'];
    ctaLabel?: string;
    ctaLink?: Reference;
    clearDefaultWrapperStyles?: boolean;
    slidesPerView?: number;
    innerArrows?: boolean;
    solidArrows?: boolean;
    classNames?: {
        title?: string;
    };
    variant?: 'normal' | 'cart';
}

const ProductSlider: FC<ProductSliderProps> = ({
    products,
    title,
    subline,
    ctaLabel,
    ctaLink,
    titleVariant = 'lg',
    subtitleVariant = 'lg',
    clearDefaultWrapperStyles = true,
    innerArrows = true,
    solidArrows = false,
    breakpoints = {},
    classNames = {},
    ...props
}) => {
    const titleClassName = useClassNames([
        'mb-12 text-20 text-slider-title',
        classNames.title,
        {
            xs: 'md:text-16',
            sm: 'md:text-18 lg:text-22',
            lg: 'md:text-22 lg:text-32'
        }[titleVariant ?? 'lg']
    ]);

    return (
        <Wrapper background="neutral-200" clearDefaultStyles={clearDefaultWrapperStyles} className="m-auto pl-16 md:pl-0 lg:max-w-[1278px]">
            <div>
                <Typography className={titleClassName} as="h3">
                    {title}
                </Typography>
                {(subline ?? ctaLink) && (
                    <div className="mt-8 flex items-center justify-between md:mt-16 lg:mt-14">
                        {subline && <Subtitle subtitle={subline} variant={subtitleVariant} />}
                        {ctaLink && (
                            <div className="hidden lg:block">
                                <Link target={ctaLink} withArrow>
                                    <span className="font-medium leading-[24px] text-secondary-black">{ctaLabel}</span>
                                </Link>
                            </div>
                        )}
                    </div>
                )}
                {ctaLink && (
                    <div className="mt-20 block md:mt-16 lg:hidden">
                        <Link target={ctaLink} withArrow>
                            <span className="font-medium leading-[24px] text-secondary-black">{ctaLabel}</span>
                        </Link>
                    </div>
                )}
            </div>
            <div className="mt-20 md:mt-24 lg:mt-20">
                <div className="relative m-auto mt-6 w-full">
                    <Slider
                        slidesPerView={1.37}
                        allowArrowsOnTouchDevice={false}
                        slidesPerGroup={1}
                        dots={false}
                        arrows
                        innerArrows={innerArrows}
                        solidArrows={solidArrows}
                        nextButtonStyles={{ transform: 'translateY(-100%)' }}
                        prevButtonStyles={{ transform: 'translateY(-100%)' }}
                        allowTouchMove
                        spaceBetween={16}
                        breakpoints={{
                            [tablet]: {
                                slidesPerView: 2.37,
                                slidesPerGroup: 2,
                                spaceBetween: 16,
                                ...(breakpoints[tablet] ?? {})
                            },
                            [mediumDesktop]: {
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                                spaceBetween: 16,
                                ...(breakpoints[mediumDesktop] ?? {})
                            }
                        }}
                        {...props}
                    >
                        {products.map(product => (
                            <Tile key={product.productId} product={product} />
                        ))}
                    </Slider>
                </div>
            </div>
        </Wrapper>
    );
};

export default ProductSlider;
