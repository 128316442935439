'use client';
import React from 'react';
import type { LearnerOrderLineItemType } from '@wilm/common';
import Button from 'components/commercetools-ui/atoms/button';
import { useFormat } from 'helpers/hooks/useFormat';

interface LearnerOrderLineItemTypeProps {
    lineItem: LearnerOrderLineItemType;
    openAssigningModal: (lineItemId: string) => void;
}

const LearnerOrderLineItem: React.FC<LearnerOrderLineItemTypeProps> = ({ lineItem, openAssigningModal }) => {
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

    return (
        <React.Fragment key={lineItem.lineItemId}>
            <p className="bg-gray-100 p-8 text-right text-md sm:hidden">{formatCommonMessage({ id: 'line.item' })}</p>
            <p className="p-8 text-left  font-bold">{lineItem.name}</p>
            <p className="bg-gray-100 p-8 text-right text-md sm:hidden">{formatCommonMessage({ id: 'unassigned.seats' })}</p>
            <p
                className={`p-8 ${lineItem.isProvisionEnabled ? 'font-bold sm:text-xl' : ''} ${lineItem.isProvisionEnabled && lineItem.seats.available ? 'text-learner-accent' : ''}`}
            >
                {lineItem.isProvisionEnabled ? lineItem.seats.available : 'N/A'}
            </p>

            <p className="bg-gray-100 p-8 text-right text-md sm:hidden">{formatCommonMessage({ id: 'total.seats' })}</p>
            <p className="p-8 font-bold sm:text-xl">{lineItem.seats.total}</p>
            <p className="bg-gray-100 p-8 text-right text-md sm:hidden">{formatCommonMessage({ id: 'action' })}</p>
            <div>
                {!lineItem.isProvisionEnabled && (
                    <p className="p-8 text-center text-sm">
                        {formatCommonMessage({
                            id: 'no.assignments.available',
                            defaultMessage: 'No assignments available'
                        })}
                    </p>
                )}
                {lineItem.isProvisionEnabled && !!lineItem.seats.available && (
                    <Button
                        variant="primary"
                        onClick={() => {
                            openAssigningModal(lineItem.lineItemId);
                        }}
                    >
                        {formatCommonMessage({ id: 'add.learners' })}
                    </Button>
                )}

                {lineItem.isProvisionEnabled && !lineItem.seats.available && (
                    <Button
                        variant="secondary"
                        onClick={() => {
                            openAssigningModal(lineItem.lineItemId);
                        }}
                    >
                        {formatCommonMessage({ id: 'view.learners' })}
                    </Button>
                )}
            </div>
            <div className="col-span-full my-8 border-b"></div>
        </React.Fragment>
    );
};

export default LearnerOrderLineItem;
