import type { Fields } from '../types/validation-rules';

export const validate = (field: Fields[keyof Fields], fields: Fields) => {
    if (field.validation.required && !field.value?.toString()) {
        return { showError: true, message: field.validation.errorMessages?.validation };
    } else if (field.validation.requiredPredicate?.(fields) && !field.value) {
        return { showError: true, message: field.validation.errorMessages?.validation };
    } else if (
        typeof field.value === 'string' &&
        field.value.length > 0 &&
        ((!!field.validation.minLength && field.value.length < field.validation.minLength) ||
            (!!field.validation.min && parseInt(field.value) < field.validation.min))
    ) {
        return { showError: true, message: field.validation.errorMessages?.range };
    } else if (
        typeof field.value === 'string' &&
        ((!!field.validation.maxLength && field.value.length > field.validation.maxLength) ||
            (!!field.validation.max && parseInt(field.value) > field.validation.max))
    ) {
        return { showError: true, message: field.validation.errorMessages?.range };
    } else if (
        typeof field.value === 'string' &&
        field.value.length &&
        field.validation.regex &&
        !field.validation.regex.test(field.value)
    ) {
        return { showError: true, message: field.validation.errorMessages?.regex };
    } else if (field.validation.sameAsPredicate && !field.validation.sameAsPredicate?.(fields)) {
        return { showError: true, message: field.validation.errorMessages?.validation };
    } else if (field.validation.validate) {
        return field.validation.validate(fields);
    }

    return {};
};
