import { useCallback } from 'react';
import type { WishListLineItem, Wishlist } from '@wilm/common';
import cookie from 'js-cookie';
import type { SWRResponse } from 'swr';
import useSWR, { mutate } from 'swr';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useSession } from 'providers/session';
import { sdk } from 'sdk';
import { revalidateOptions } from 'frontastic';

type WishlistResponse = {
    wishlist?: Wishlist;
    umbracoSession: string;
};

const useWishlist = () => {
    const extensions = sdk.composableCommerce;

    const sessionSettings = useSession();
    const { wishlist } = useBrandSettingsContext();

    const fetcher = async () => {
        if (wishlist?.enabled) {
            return extensions.wishlist.getWishlist();
        }
        return { data: { wishlist: { wishlistId: '', lineItems: [] }, umbracoSession: '' } as WishlistResponse, isError: false };
    };

    const result = useSWR('/action/wishlist/getWishlist', fetcher, revalidateOptions);

    const data = {} as SWRResponse<Wishlist>;
    if (!result.data?.isError) {
        const res = result.data?.data as unknown as WishlistResponse;
        data.data = res?.wishlist;
        if (!sessionSettings?.sessionCookieName || !sessionSettings?.sessionCookieDomain) {
            throw new Error('Session settings are not set!');
        }
        if (res?.umbracoSession) {
            cookie.set(sessionSettings.sessionCookieName, res?.umbracoSession, {
                domain: sessionSettings.sessionCookieDomain
            });
        }
    }

    const totalWishlistItems = data.data?.lineItems?.reduce((acc, curr) => acc + curr.count!, 0) ?? 0;

    const addToWishlist = useCallback(async (wishlist: Wishlist, lineItem: WishListLineItem, count = 1) => {
        const extensions = sdk.composableCommerce;

        const newWishlist = { ...wishlist, lineItems: [...(wishlist.lineItems ?? []), lineItem] };

        const res = extensions.wishlist.addItem({ variant: { sku: lineItem.variant!.sku }, count });

        await mutate('/action/wishlist/getWishlist', res, {
            optimisticData: { data: newWishlist },
            rollbackOnError: true
        });
    }, []);

    const removeLineItem = useCallback(async (wishlist: Wishlist, lineItem: WishListLineItem) => {
        const extensions = sdk.composableCommerce;

        const newWishlist = {
            ...wishlist,
            lineItems: wishlist.lineItems?.filter(item => item.lineItemId !== lineItem.lineItemId) ?? []
        };

        const res = extensions.wishlist.removeItem({ lineItem: { id: lineItem.lineItemId } });
        await mutate('/action/wishlist/getWishlist', res, {
            optimisticData: { data: newWishlist },
            rollbackOnError: true
        });
    }, []);

    const clearWishlist = useCallback(async (wishlist: Wishlist) => {
        const res = await sdk.callAction({ actionName: 'wishlist/clearWishlist' });
        const newWishlist = {
            ...wishlist,
            lineItems: []
        };

        if (!res.isError) {
            await mutate('/action/wishlist/getWishlist', res.data, {
                optimisticData: { data: newWishlist },
                rollbackOnError: true
            });
        }
    }, []);

    const updateLineItem = useCallback(async (wishlist: Wishlist, lineItem: WishListLineItem, count = 1) => {
        const extensions = sdk.composableCommerce;

        const newWishlist = {
            ...wishlist,
            lineItems:
                wishlist.lineItems?.map(item => {
                    if (item.lineItemId === lineItem.lineItemId) {
                        return { ...lineItem, count: ++count };
                    }
                }) ?? []
        };

        const res = await extensions.wishlist.updateItem({ lineItem: { id: lineItem.lineItemId }, count });

        if (!res.isError) {
            await mutate('/action/wishlist/getWishlist', res.data, {
                optimisticData: newWishlist,
                rollbackOnError: true
            });
        }
    }, []);

    return {
        ...data,
        totalItems: totalWishlistItems,
        addToWishlist,
        removeLineItem,
        clearWishlist,
        updateLineItem
    };
};

export default useWishlist;
