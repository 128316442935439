import type { FC } from 'react';
import React from 'react';
import type { MenuCategory } from '@wilm/common';
import Drawer from 'components/commercetools-ui/atoms/drawer';
import type { Reference } from 'types/reference';
import type { ImageProps } from 'frontastic/lib/image/types';
import MobileMenu from './content/mobile-menu';

export interface Props {
    links: MenuCategory[];
    showMenu: boolean;
    hideHeaderMenu: () => void;
    socialMedia?: SocialMedia[];
}

export interface SocialMedia {
    logo: ImageProps;
    reference: Reference;
}

const HeaderNavigationMobile: FC<Props> = ({ links, showMenu, socialMedia, hideHeaderMenu }) => {
    return (
        <Drawer
            isOpen={showMenu}
            isMenu={true}
            direction="right"
            className={`bg-header-bg sm:w-[70vw] lg:w-[80vw] lg:max-w-[1284px]`}
            onClose={hideHeaderMenu}
        >
            <MobileMenu links={links} hideHeaderMenu={hideHeaderMenu} socialMedia={socialMedia} isOpen={showMenu} />
        </Drawer>
    );
};

export default HeaderNavigationMobile;
