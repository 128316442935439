import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import { validate } from '@wilm/common';
import { FieldType, type FieldErrors, type Fields, type StringFieldDefinition } from '@wilm/common';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import { useFormat } from 'helpers/hooks/useFormat';

export interface Props {
    fields: Fields;
    formName: string;
    validateOnBlur?: boolean;
    showSubmitButton?: boolean;
    submitButtonText?: string;
    submitButtonLoading?: boolean;
    handleFieldChange: (field: StringFieldDefinition, value: string) => void;
    onSubmit: (fields: Fields) => Promise<{ isError: true; errorMessage: string; fieldErrors: FieldErrors } | { isError: false }>;
    errors?: { isError: true; errorMessage: string; fieldErrors: FieldErrors };
}

const FieldsForm: FC<Props> = ({
    fields,
    formName,
    validateOnBlur,
    showSubmitButton = true,
    submitButtonText = 'Submit',
    submitButtonLoading = false,
    handleFieldChange,
    onSubmit,
    errors
}) => {
    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });
    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });

    const [formErrors, setFormErrors] = useState<FieldErrors>(errors?.fieldErrors ?? {});
    const [errorMessage, setErrorMessage] = useState<string>(errors?.errorMessage ?? '');

    const checkFields = useCallback(
        (fields: Fields) => {
            const errors: FieldErrors = {};
            let hasErrors = false;

            for (const field of Object.values(fields)) {
                if (typeof field.value === 'string') {
                    field.value = field.value.trim();
                    handleFieldChange(field as StringFieldDefinition, field.value);
                }

                const error = validate(field, fields);
                if (error) {
                    errors[field.name] = error;
                    if (Object.keys(error).length) {
                        hasErrors = true;
                    }
                }
            }

            return { errors, hasErrors };
        },
        [handleFieldChange]
    );

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const { errors, hasErrors } = checkFields(fields);

            if (hasErrors) {
                setFormErrors(errors);
                setErrorMessage('');
                return;
            }

            const submitResponse = await onSubmit(fields);

            if (submitResponse.isError) {
                if (submitResponse.errorMessage) {
                    setErrorMessage(submitResponse.errorMessage);
                    setFormErrors({});
                    return;
                } else if (submitResponse.fieldErrors) {
                    setFormErrors(submitResponse.fieldErrors);
                    setErrorMessage('');
                }
                return;
            }
            setFormErrors({});
            setErrorMessage('');
        },
        [fields, onSubmit, checkFields]
    );

    const handleFieldBlur = useCallback((field: StringFieldDefinition, fields: Fields) => {
        const error = validate(field, fields);
        if (error) {
            setFormErrors(prevState => ({ ...prevState, [field.name]: error }));
        }
    }, []);

    return (
        <form autoComplete="off" onSubmit={e => handleSubmit(e)} name={formName} noValidate>
            <div className="flex flex-col gap-25 text-base leading-6 md:gap-20">
                {Object.values(fields).map(field => {
                    // If the field is hidden, don't render it
                    if (field?.showOnPredicate?.(fields) === false) {
                        return null;
                    }

                    // Check if the field is required
                    const isRequired = field.validation.required ?? field.validation.requiredPredicate?.(fields) ?? false;

                    const errorMessage = formErrors?.[field.name]?.message;

                    const formattedErrorMessage = errorMessage
                        ? formatFieldErrorsMessage({
                              id: errorMessage,
                              defaultMessage: errorMessage
                          })
                        : '';

                    // Render the field based on its type
                    if (field.type === FieldType.STRING) {
                        return (
                            <div key={field.name}>
                                <Input
                                    id={field.name}
                                    name={field.name}
                                    type="text"
                                    label={formatFieldLabelsMessage({
                                        id: `${formName}.${field.name}.label`,
                                        defaultMessage: field.name
                                    })}
                                    placeholder={field.placeholder}
                                    disabled={field.disabled}
                                    value={field.value}
                                    minLength={field.validation.minLength}
                                    maxLength={field.validation.maxLength}
                                    required={isRequired}
                                    onChange={e => {
                                        handleFieldChange(field, e.target.value);
                                    }}
                                    onBlur={() => {
                                        validateOnBlur && handleFieldBlur(field, fields);
                                    }}
                                    errorMessage={formattedErrorMessage}
                                />
                            </div>
                        );
                    }
                })}

                {errorMessage && <p className="text-sm text-input-error">{errorMessage}</p>}

                {showSubmitButton && (
                    <Button variant="primary" size="full" type="submit" className="rounded-md md:rounded-lg" loading={submitButtonLoading}>
                        {submitButtonText}
                    </Button>
                )}
            </div>
        </form>
    );
};

export default FieldsForm;
