import type { FC } from 'react';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { CartInventoryItemErrorMessageEnum, ProductTypeKey } from '@wilm/common';
import type { LineItem, MembershipAutoRenewalType } from '@wilm/common';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import type { HandleIsRenewableChangeType } from 'frontastic/hooks/useCart/types';
import useLineItem from 'frontastic/hooks/useLineItem';
import type { BundleLineItemData, MembershipLineItemData, TrainingLineItemData } from 'frontastic/hooks/useLineItem/types';
import AdhocCartItem from './adhoc-line-item';
import BundleCartItem from './bundle-line-item';
import CartItemContainer from './cart-item-container';
import MembershipCartItem from './membership-line-item';
import TrainingCartItem from './training-line-item';

export interface CartItemProps {
    item: LineItem;
    Container?: React.ElementType;
    hideAttributes?: boolean;
    showPrice?: boolean;
    showQuantity?: boolean;
    showQuantityEdit?: boolean;
    showRemove?: boolean;
    handleIsRenewableChange?: HandleIsRenewableChangeType;
    membershipAutoRenewal?: MembershipAutoRenewalType;
    autoRenewalPolicy?: string;
    setIsRemoveMembershipModalOpen?: (data: boolean) => void;
    bundleItems?: LineItem[];
    isCart?: boolean;
    removeItemFromCart?: (lineItemId: string) => Promise<void>;
    updateItem?: (lineItemId: string, quantity: number) => Promise<void>;
    inventoryCheck?: {
        outOfStockItems: Record<string, { message: CartInventoryItemErrorMessageEnum; availableQuantity: number }>;
    };
    hasDelegateErrors: boolean;
}

const CartItem: FC<CartItemProps> = ({
    item,
    Container = CartItemContainer,
    hideAttributes = false,
    showPrice = true,
    showQuantity = false,
    showQuantityEdit = true,
    showRemove = true,
    handleIsRenewableChange,
    membershipAutoRenewal,
    autoRenewalPolicy,
    setIsRemoveMembershipModalOpen,
    bundleItems,
    isCart,
    removeItemFromCart,
    updateItem,
    inventoryCheck,
    hasDelegateErrors
}) => {
    const { account } = useAccount();

    const productTypeKey = item?.productTypeKey ?? '';
    const disabledQuantity = !account?.isB2B; // && item.variant?.attributes?.['limited-quantity'];

    const [processing, setProcessing] = useState(false);

    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });

    const { lineItemData: hookLineItemData } = useLineItem(item);

    const [showDelegateSelection, setShowDelegateSelection] = useState(false);

    let lineItemData;

    let inventoryCheckError = '';
    const itemInventoryCheckError = useMemo(
        () => inventoryCheck?.outOfStockItems?.[item.variant?.sku ?? ''],
        [inventoryCheck, item.variant?.sku]
    );

    switch (itemInventoryCheckError?.message) {
        case CartInventoryItemErrorMessageEnum.OUT_OF_STOCK:
            inventoryCheckError = formatErrorMessage({ id: 'error.lineItem.outofstock', defaultMessage: 'Out of stock' });
            break;
        case CartInventoryItemErrorMessageEnum.NOT_ENOUGH_AVAILABLE:
            inventoryCheckError = formatErrorMessage({
                id: 'error.lineItem.notenoughavailable',
                defaultMessage: 'There are only {qty} seats available to purchase.',
                values: { qty: itemInventoryCheckError.availableQuantity }
            });
            break;
        default:
            break;
    }

    const updateCartItem = useCallback(
        async (newQuantity: number) => {
            if (!removeItemFromCart || !updateItem) return;
            const action = item?.count && newQuantity > item?.count ? 'add' : 'remove';

            if (processing) return;

            setProcessing(true);

            if (newQuantity < 1) await removeItemFromCart(item.lineItemId);
            else await updateItem(item.lineItemId, newQuantity);

            if (action === 'add') {
                track({ type: TagsActionType.ADD_TO_CART, payload: { item: item, quantity: 1 } });
            } else if (action === 'remove') {
                track({ type: TagsActionType.REMOVE_FROM_CART, payload: { item: item, quantity: 1 } });
            }

            setProcessing(false);
        },
        [updateItem, removeItemFromCart, processing, item]
    );

    const removeItem = useCallback(
        async (lineItemId: string) => {
            if (!removeItemFromCart) return;
            if (item.isAutoAdded) {
                if (setIsRemoveMembershipModalOpen) setIsRemoveMembershipModalOpen(true);
            } else {
                await removeItemFromCart(lineItemId);
                track({ type: TagsActionType.REMOVE_FROM_CART, payload: { item: item, quantity: item?.count ?? 1 } });
            }
        },
        [processing, item]
    );

    useEffect(() => {
        if (account?.isB2B && isCart) {
            setShowDelegateSelection(true);
        }
    }, [account?.isB2B]);

    const counterClassName = useClassNames([
        'flex w-fit items-center gap-14  border border-input-border hover:border-input-border',
        processing ? 'cursor-not-allowed bg-neutral-300' : 'cursor-pointer bg-white'
    ]);

    switch (productTypeKey) {
        case ProductTypeKey.TRAINING:
            lineItemData = hookLineItemData as TrainingLineItemData;
            return (
                <Container>
                    <TrainingCartItem
                        item={item}
                        learningMode={lineItemData.learningModeWithLabel}
                        startDate={lineItemData.startDateWithLabel}
                        location={lineItemData.locationWithLabel}
                        counterClassName={counterClassName}
                        updateCartItem={updateCartItem}
                        removeItem={showRemove ? removeItem : undefined}
                        hideAttributes={hideAttributes}
                        showPrice={showPrice}
                        showQuantity={showQuantity}
                        showQuantityEdit={showQuantityEdit}
                        disabledQuantity={disabledQuantity}
                        inventoryCheckError={inventoryCheckError}
                        showDelegateSelection={showDelegateSelection}
                        hasDelegateErrors={hasDelegateErrors}
                    />
                </Container>
            );
        case ProductTypeKey.OTHER:
        case ProductTypeKey.PROFESSIONAL_SERVICE:
            return (
                <Container>
                    <AdhocCartItem
                        counterClassName={counterClassName}
                        removeItem={showRemove ? removeItem : undefined}
                        updateCartItem={updateCartItem}
                        item={item}
                        disabledQuantity={disabledQuantity}
                        showPrice={showPrice}
                        showQuantity={showQuantity}
                        showQuantityEdit={showQuantityEdit}
                        inventoryCheckError={inventoryCheckError}
                    />
                </Container>
            );
        case ProductTypeKey.MEMBERSHIP:
            lineItemData = hookLineItemData as MembershipLineItemData;

            return (
                <Container isAutoAdded={item?.isAutoAdded}>
                    <MembershipCartItem
                        updateCartItem={updateCartItem}
                        removeItem={showRemove ? removeItem : undefined}
                        item={item}
                        counterClassName={counterClassName}
                        duration={lineItemData.durationWithLabel}
                        hideAttributes={hideAttributes}
                        disabledQuantity={disabledQuantity}
                        handleIsRenewableChange={handleIsRenewableChange}
                        membershipAutoRenewal={membershipAutoRenewal}
                        showDelegateSelection={showDelegateSelection}
                        autoRenewalPolicy={autoRenewalPolicy}
                        showPrice={showPrice}
                        showQuantity={showQuantity}
                        showQuantityEdit={showQuantityEdit}
                        inventoryCheckError={inventoryCheckError}
                        hasDelegateErrors={hasDelegateErrors}
                    />
                </Container>
            );
        case ProductTypeKey.BUNDLE:
            lineItemData = hookLineItemData as BundleLineItemData;
            return (
                <Container>
                    <BundleCartItem
                        updateCartItem={updateCartItem}
                        removeItem={showRemove ? removeItem : undefined}
                        item={item}
                        counterClassName={counterClassName}
                        disabledQuantity={disabledQuantity}
                        bundleItems={bundleItems}
                        showPrice={showPrice}
                        showQuantity={showQuantity}
                        showQuantityEdit={showQuantityEdit}
                        inventoryCheckError={inventoryCheckError}
                        showDelegateSelection={showDelegateSelection}
                        hasDelegateErrors={hasDelegateErrors}
                    />
                </Container>
            );
        default:
            return <></>;
    }
};

export default CartItem;
