import React, { useCallback, useMemo, useState } from 'react';
import type { Product, CreateInHousePayload, InHouseFields, DateFieldDefinition, FieldErrors } from '@wilm/common';
import { validate, FieldType, inHouseFieldsDefinitions } from '@wilm/common';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Input from 'components/commercetools-ui/atoms/input';
import Modal from 'components/commercetools-ui/atoms/modal';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { getDateWithTimeZone } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';
import { useSalesLinkDataContext } from 'providers/sales-link/data';

export interface Props {
    product: Product;
}

const CreateInHouse: React.FC<Props> = ({ product }) => {
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'field-errors' });
    const { formatMessage: formatSalesLinkMessage } = useFormat({ name: 'sales-link' });
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

    const [isCreateInHouseModalOpen, setIsCreateInHouseModalOpen] = useState(false);

    const [inHouseFields, setInHouseFields] = useState<InHouseFields>(inHouseFieldsDefinitions);
    const [datesErrors, setDatesErrors] = useState({} as FieldErrors);

    const { checkIfCreateInHouseEventIsAvailable, createInHouseEvent } = useSalesLinkCartContext();

    const { salesLinkSettings } = useSalesLinkDataContext();

    const { isRelated, originMasterSku } = useMemo(() => {
        return {
            isRelated: !!product.masterVariantRelatedCourse,
            originMasterSku: product.masterVariantRelatedCourse ?? product.key
        };
    }, [product]);

    const relatedMessage = useMemo(() => {
        return salesLinkSettings.createInHouseForRelatedProductMessage?.replace('{originMasterSku}', originMasterSku);
    }, [originMasterSku, salesLinkSettings.createInHouseForRelatedProductMessage]);

    const setDateFieldsValue = useCallback(
        (fieldName: keyof InHouseFields, value: string) => {
            if (fieldName === 'capacity' && !inHouseFields.capacity.validation.regex?.test(value)) {
                return;
            }
            setInHouseFields(prev => {
                return {
                    ...prev,
                    [fieldName]: {
                        ...prev[fieldName],
                        value
                    } as DateFieldDefinition
                };
            });
        },
        [setInHouseFields, inHouseFields]
    );

    const openCreateInHouseModal = useCallback(async () => {
        const isAvailable = await checkIfCreateInHouseEventIsAvailable();
        if (isAvailable) {
            setIsCreateInHouseModalOpen(true);
        }
    }, [checkIfCreateInHouseEventIsAvailable, setIsCreateInHouseModalOpen]);

    const closeCreateInHouseModal = useCallback(() => {
        setIsCreateInHouseModalOpen(false);
        setDateFieldsValue('startDate', inHouseFieldsDefinitions.startDate.value);
        setDateFieldsValue('timeZone', inHouseFieldsDefinitions.timeZone.value);
        setDatesErrors({} as FieldErrors);
    }, []);

    const handleCreateInHouseEvent = useCallback(async () => {
        let isValid = true;
        const newDatesErrors: FieldErrors = {};
        for (const key in inHouseFields) {
            const dateField = inHouseFields[key as keyof InHouseFields];
            const fieldErrors = validate(dateField, inHouseFields);

            if (fieldErrors.showError) {
                isValid = false;
                newDatesErrors[key] = fieldErrors;
            }
        }

        setDatesErrors(newDatesErrors);

        if (!isValid) {
            return;
        }

        const timeZone = inHouseFields.timeZone.value;

        const productInfo: CreateInHousePayload['product'] = {
            startDate: getISOStringFromDateInput(inHouseFields.startDate.value, timeZone),
            timezoneName: inHouseFields.timeZone.value,
            capacity: +inHouseFields.capacity.value,
            trainingProductSku: originMasterSku
        };

        await createInHouseEvent(productInfo);

        closeCreateInHouseModal();
    }, [inHouseFields, createInHouseEvent, originMasterSku, closeCreateInHouseModal]);

    const getISOStringFromDateInput = (date: string, timeZone: string) => {
        const [datePart, timePart] = date.split('T');
        const [year, month, day] = datePart.split('-');
        const [hour, minute] = timePart.split(':');
        const dateWithTimeZone = getDateWithTimeZone(timeZone, {
            year: Number(year),
            month: Number(month),
            day: Number(day),
            hour: Number(hour),
            minute: Number(minute),
            second: 0
        });
        return dateWithTimeZone.toISOString();
    };

    const noticeMarkdown = useMemo(() => {
        const administrateCourseId = product.masterVariantAdministrateCourseId;
        const administrateCourseDetailsURL = `https://wilmingtonuat.administrateapp.com/ux/training/courses/${administrateCourseId}#schedule`;
        const administrateCourseDetailsLink = `<a className="w-full md:w-auto underline text-blue-500 md:no-underline" target="_blank" href="${administrateCourseDetailsURL}">${salesLinkSettings.createInHouseAdministrateLinkText}</a>`;

        let documentationURL = '';
        if (salesLinkSettings.createInHouseDocumentationLink?.type === 'link') {
            documentationURL = salesLinkSettings.createInHouseDocumentationLink.link;
        } else if (salesLinkSettings.createInHouseDocumentationLink?.type === 'page-folder') {
            documentationURL = salesLinkSettings.createInHouseDocumentationLink.pageFolder._url;
        }

        const documentationLink = `<a className="w-full md:w-auto underline text-green-500 md:no-underline" href="${documentationURL}" target="${salesLinkSettings.createInHouseDocumentationLink?.openInNewWindow ? '_blank' : '_self'}" > ${salesLinkSettings.createInHouseDocumentationLinkText} </a>`;

        return (
            salesLinkSettings.createInHouseNoticeMessage
                ?.replace('{administrateCourseDetailsLink}', administrateCourseDetailsLink)
                .replace('{documentationLink}', documentationLink) ?? ''
        );
    }, [salesLinkSettings, product]);

    return (
        <>
            <Button variant="secondary" size="xs" onClick={openCreateInHouseModal}>
                {formatSalesLinkMessage({ id: 'create.new.in.house.event', defaultMessage: '+ Create a new In-house event' })}
            </Button>

            <Modal
                isOpen={isCreateInHouseModalOpen}
                className="relative w-[90%] rounded-md bg-white"
                preventScroll={true}
                style={{ content: { maxWidth: '1000px' } }}
            >
                <div className="max-h-screen overflow-auto p-40">
                    <h4>
                        {product.name} {product.key}
                    </h4>
                    {isRelated && <p className="mt-8 text-accent-2">{relatedMessage}</p>}
                    <div className="mt-20 grid grid-cols-1 gap-16 md:grid-cols-3">
                        {Object.entries(inHouseFields).map(([key, field]) => (
                            <div key={field.name}>
                                {field.type === FieldType.ENUM && (
                                    <Dropdown
                                        name={field.name}
                                        items={field.options}
                                        className="w-full"
                                        onChange={e => {
                                            setDateFieldsValue(key, e.target.value);
                                        }}
                                        label={field.label}
                                        required={field.validation.required}
                                        value={field.value}
                                        errorMessage={
                                            datesErrors?.[key]?.message
                                                ? formatErrorMessage({
                                                      id: datesErrors?.[key]?.message ?? '',
                                                      defaultMessage: datesErrors?.[key]?.message ?? ''
                                                  })
                                                : undefined
                                        }
                                    />
                                )}
                                {(field.type === FieldType.DATE || field.type === FieldType.NUMBER) && (
                                    <Input
                                        type={field.type}
                                        label={field.label}
                                        name={field.name}
                                        min={field.validation.min ?? field.validation.minPredicate?.(inHouseFields)}
                                        max={field.validation.max}
                                        value={field.value}
                                        required={field.validation.required}
                                        onChange={e => {
                                            const date = e.target.value;
                                            setDateFieldsValue(key, date);
                                        }}
                                        errorMessage={
                                            datesErrors?.[key]?.message
                                                ? formatErrorMessage({
                                                      id: datesErrors?.[key]?.message ?? '',
                                                      defaultMessage: datesErrors?.[key]?.message ?? ''
                                                  })
                                                : undefined
                                        }
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    <p className="mt-24 text-lg font-semibold text-accent-2">{salesLinkSettings.createInHouseNoticeHeading}</p>
                    <Markdown className="mt-8" markdown={noticeMarkdown} />

                    <div className="mt-36 flex justify-end">
                        <div className="grid w-full grid-cols-1 gap-8 md:w-auto md:grid-cols-2">
                            <Button variant="secondary" onClick={closeCreateInHouseModal}>
                                {formatCommonMessage({ id: 'close', defaultMessage: 'Close' })}
                            </Button>
                            <Button variant="primary" onClick={() => handleCreateInHouseEvent()}>
                                {formatCommonMessage({ id: 'create.in.house.event', defaultMessage: 'Create In House event' })}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateInHouse;
