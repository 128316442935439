import type { FieldErrors } from '../../types/validation-rules';

export class FieldsValidationError extends Error {
    protected code?: string;
    errors: FieldErrors;

    constructor({ errors }: { errors?: FieldErrors }) {
        super('Form has incorrect fields');

        this.code = 'FORM_VALIDATION_ERROR';
        this.errors = errors ?? {};
    }
}
