'use client';
import React, { useState } from 'react';
import type { SalesLinkInfoType } from '@wilm/common';
import Countdown from 'components/sales-link/atoms/countdown';
import CustomerInfo from 'components/sales-link/organisms/content/customer-info';
import ProductSearch from 'components/sales-link/organisms/content/products';
import CartSummary from 'components/sales-link/organisms/content/summary';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';
import CurrencySelectorModal from '../currency-selector-modal';

export interface Props {
    salesLinkInfo: SalesLinkInfoType;
    termsAndConditions: string;
}

const SalesLinkContent: React.FC<Props> = ({ salesLinkInfo, termsAndConditions }) => {
    const customer = salesLinkInfo.customer;
    const { selectedCurrency, handleCurrencySelection } = useSalesLinkCartContext();
    const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(!selectedCurrency);

    return (
        <>
            <div className="bg-checkout-bg md:p-16 md:pb-40">
                <div className=" bg-white p-16 md:mt-20">
                    <h1 className="mb-10 text-20">Sales Link Form:</h1>
                    <p>
                        You are currently building an order for customer with email <span className="font-bold">{customer?.email}</span>
                    </p>
                    <p>Created by: {salesLinkInfo.requestedByEmail}</p>
                    <p>Please be advised that any actions on this form are recorded for auditing purposes.</p>
                    <Countdown endDateTime={salesLinkInfo.expiresAt ?? ''} />
                </div>

                {selectedCurrency && (
                    <div className=" grid-cols-3 gap-16 md:pt-20 lg:grid xl:grid-cols-4">
                        <div className="col-span-2 xl:col-span-3 ">
                            <div className="mb-0 bg-white p-16">{customer && <CustomerInfo customer={customer} />}</div>
                            <ProductSearch />
                        </div>
                        <div>
                            <CartSummary termsAndConditions={termsAndConditions} />
                        </div>
                    </div>
                )}
            </div>
            {!selectedCurrency && (
                <div className="p-40">
                    <p>Loading...</p>
                </div>
            )}
            <CurrencySelectorModal
                isOpen={isCurrencyModalOpen}
                onSubmit={(currency: string, la1Id: string) => {
                    setIsCurrencyModalOpen(false);
                    void handleCurrencySelection(currency, la1Id);
                }}
            />
        </>
    );
};

export default SalesLinkContent;
