import type { LineItem } from '@wilm/common';
import calculateFractionDigit from 'helpers/utils/calculateFractionDigit';
import type CartItemDetails from '../interfaces/cartItemDetails';

export default function getGtmItems(lineItems: LineItem[]) {
    const gtmItems: CartItemDetails | CartItemDetails[] = [];
    lineItems.forEach(item => {
        if (item.variant?.isOnStock) {
            let lineItemDiscountCentAmount = Number(item.price?.centAmount) - Number(item.variant?.discountedPrice?.centAmount) || 0;

            item.discounts?.forEach(discount => {
                lineItemDiscountCentAmount = lineItemDiscountCentAmount + (discount.discountedAmount?.centAmount ?? 0);
            });

            const formattedPrice = item.variant?.discountedPrice
                ? parseFloat(
                      Number(
                          item.variant.discountedPrice.centAmount! * calculateFractionDigit(item.variant.discountedPrice.fractionDigits!)
                      ).toFixed(2)
                  )
                : parseFloat(Number(item.price!.centAmount! * calculateFractionDigit(item.price!.fractionDigits!)).toFixed(2));

            const lineItemDiscountFormatted = parseFloat(
                Number(lineItemDiscountCentAmount * calculateFractionDigit(item.price!.fractionDigits!)).toFixed(2)
            );
            gtmItems.push({
                item_id: item.variant?.sku,
                item_name: item.name!,
                price: formattedPrice,
                quantity: item.count ?? 1,
                discount: lineItemDiscountFormatted
            });
        }
    });
    return gtmItems;
}
