import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

export interface Props {
    markdown: string;
    className?: string;
}

const Markdown: React.FC<Props> = ({ markdown, className }) => {
    return (
        <div className={`markdown ${className || ''}`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{markdown}</ReactMarkdown>
        </div>
    );
};

export default Markdown;
