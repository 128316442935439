import type { Cart } from '../../types/cart/Cart';
import type { CartInstalmentOption, InstalmentInfoForPayment, Payment } from '../../types/cart/Payment';
import type { LineItem } from '../../types/cart/LineItem';
import { PaymentMethodType } from '../../enum/cart';
import { AccountStatus, AccountType } from '../../enum/account';
import type { Variant } from '../../types/product/Variant';

export class InstalmentHelper {
    private static readonly ALLOWED_ACCOUNT_STATUSES = [AccountStatus.OPEN];
    private static readonly ALLOWED_ACCOUNT_TYPES = [AccountType.B2C];

    public static checkCartForInstalmentOption(
        cart: Cart,
        options?: {
            accountType: AccountType;
            accountStatus?: AccountStatus;
            instalmentOption?: number | null;
        }
    ):
        | {
              isValid: false;
              message: string;
          }
        | {
              isValid: true;
          } {
        const { instalmentOption } = options || {};

        if (instalmentOption && (instalmentOption < 1 || instalmentOption > 12)) {
            return {
                isValid: false,
                message: 'Instalment option must be between 1 and 12.'
            };
        }

        const cartInstalmentOptions = this.getCartInstalmentOptions(cart, options);

        if (!cartInstalmentOptions.some(option => option.value === instalmentOption)) {
            return {
                isValid: false,
                message: 'Instalment option is not valid for this cart.'
            };
        }

        return { isValid: true };
    }

    public static getCartInstalmentOptions(
        cart: Cart,
        options?: {
            accountType: AccountType;
            accountStatus?: AccountStatus;
        }
    ): CartInstalmentOption[] {
        // Early return checks
        if (!this.isAccountEligible(options?.accountStatus, options?.accountType)) {
            console.error('Instalment payment method is not available for the account.');
            return [];
        }

        const totalAmount = cart.taxedPrice?.centAmount;
        if (!totalAmount) {
            console.error('Total amount is missing in cart');
            return [];
        }

        const lineItems = cart.lineItems?.filter(Boolean);
        if (!lineItems?.length) {
            console.error('Line items are missing in cart');
            return [];
        }

        const commonOptions = this.findCommonInstalmentOptions(lineItems);
        if (!commonOptions.length) {
            console.error('No common instalment options found for line items');
            return [];
        }

        return this.createCartInstalmentOptions(commonOptions, totalAmount, cart.taxedPrice.currencyCode!);
    }
    public static getInstalmentsInfoForPayment(payment?: Payment): InstalmentInfoForPayment {
        if (!payment)
            return {
                isInstalmentPayment: false,
                currentInstalmentMoney: null,
                instalmentNumber: null,

                totalInstalments: null
            };

        const instalmentNumber = payment.instalmentNumber;
        const totalInstalments = payment.paymentInstalmentOption;

        const isInstalmentPayment = !!(instalmentNumber && totalInstalments && totalInstalments > 1 && totalInstalments < 13);

        if (!isInstalmentPayment)
            return { isInstalmentPayment: false, currentInstalmentMoney: null, instalmentNumber: null, totalInstalments: null };

        return {
            isInstalmentPayment: true,
            currentInstalmentMoney: payment.amountPlanned,
            instalmentNumber,
            totalInstalments
        };
    }

    private static isAccountEligible(accountStatus?: AccountStatus, accountType?: AccountType): boolean {
        return (
            !!accountStatus &&
            this.ALLOWED_ACCOUNT_STATUSES.includes(accountStatus) &&
            !!accountType &&
            this.ALLOWED_ACCOUNT_TYPES.includes(accountType)
        );
    }

    private static findCommonInstalmentOptions(lineItems: LineItem[]): number[] {
        const lineItemsInstalmentOptions: number[][] = [];

        for (const lineItem of lineItems) {
            const variant = lineItem.variant;
            if (!variant) {
                console.error('Variant is missing in line item', lineItem);
                return [];
            }

            // Check if instalment is supported for this variant
            const supportsInstalment = this.variantSupportsInstalment(variant);
            if (!supportsInstalment) {
                console.info('Instalment payment method is not available for the line item with sku: ' + variant.sku);
                return [];
            }

            // Get instalment options for this variant
            const options = variant.attributes?.instalmentOptions as number[];
            if (!options?.length) {
                console.error('Instalment options are missing in line item', variant);
                return [];
            }

            lineItemsInstalmentOptions.push(options);
        }

        // Find common options across all line items
        if (!lineItemsInstalmentOptions.length) {
            console.error('No instalment options found for line items');
            return [];
        }

        console.info('Line items instalment options:', lineItemsInstalmentOptions);
        const [firstArray, ...rest] = lineItemsInstalmentOptions;
        return firstArray.filter(value => rest.every(array => array.includes(value)));
    }

    private static variantSupportsInstalment(variant: Variant): boolean {
        return (variant.attributes?.supportedPaymentMethods as { key: string }[])?.some(
            method => method.key.toLowerCase() === PaymentMethodType.INSTALMENT.toLowerCase()
        );
    }

    private static createCartInstalmentOptions(
        instalmentOptions: number[],
        totalAmount: number,
        currencyCode: string
    ): CartInstalmentOption[] {
        return instalmentOptions
            .map(instalmentOption => {
                const amountPerInstalment = totalAmount / instalmentOption;
                const roundedAmountPerInstalment = Math.round(amountPerInstalment);
                const lastInstalmentPrice = Math.round(totalAmount - roundedAmountPerInstalment * (instalmentOption - 1));
                const highestPaymentAmount = Math.max(roundedAmountPerInstalment, lastInstalmentPrice);

                return {
                    value: instalmentOption,
                    highestPaymentAmount: {
                        fractionDigits: 2,
                        centAmount: highestPaymentAmount,
                        currencyCode
                    }
                };
            })
            .sort((a, b) => a.value - b.value);
    }
}
