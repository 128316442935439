import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import Modal from 'components/commercetools-ui/atoms/modal';

export interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (data: { email: string }) => string;
}

const EmailConfirmationModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
    const initialData = {
        email: ''
    };
    const [data, setData] = useState(initialData);
    const [error, setError] = useState('');

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setData({ ...data, [e.target.name]: e.target.value });
        },
        [data]
    );

    const handleClose = () => {
        setError('');
        setData(initialData);
        onClose();
    };

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const error = onSubmit(data);
            if (error) {
                setError(error);
            }
        },
        [data]
    );

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '500px' } }}
            preventScroll={false}
        >
            <form autoComplete="off" onSubmit={e => handleSubmit(e)}>
                <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                    <p className="font-bold">To confirm your order, please enter the contact email</p>
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        required
                        onChange={handleChange}
                        data-cy="email-address"
                        value={data?.email ?? ''}
                        error={error}
                        autoComplete="off"
                        placeholder="Please type in the Contact's email address"
                    />
                    <div className="flex flex-row-reverse gap-12">
                        <Button variant="primary" type="submit" className="rounded-md md:rounded-lg">
                            Create Order
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                handleClose();
                            }}
                            className="rounded-md md:rounded-lg"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default EmailConfirmationModal;
