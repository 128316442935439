import React from 'react';
import type { Address as AddressType } from '@wilm/common';
import Typography from 'components/commercetools-ui/atoms/typography';

interface Props {
    address: AddressType | undefined;
    title?: string;
    wrapperClassName?: string;
    containerClassName?: string;
}

const SimpleAddress: React.FC<Props> = ({ address, title, wrapperClassName, containerClassName }) => {
    if (!address) {
        return <></>;
    }

    const firstRow = `${address.firstName ?? ''} ${address.lastName ?? ''}`.trim();
    const secondRow = address.phone;
    const thirdRow = `${address.streetName}, ${address.additionalStreetInfo ?? ''}`.trim();
    const forthRow =
        `${address.postalCode && address.postalCode + ' '}${address.city && address.city + ' '}${address.region ?? ''}`.trim() +
        `, ${address.country ?? ''}`.trim();

    const addressInfoTypographyElements = [firstRow, secondRow, thirdRow, forthRow];

    return (
        <div className={wrapperClassName}>
            {title && (
                <Typography className="mb-16 text-20" as="h2">
                    {title}
                </Typography>
            )}

            <div className={containerClassName}>
                {addressInfoTypographyElements.map(element => (
                    <Typography key={element} className="text-md font-bold">
                        {element}
                    </Typography>
                ))}
            </div>
        </div>
    );
};

export default SimpleAddress;
