import type { Cart } from '@wilm/common';

export const getCartTransaction = ({ cart, currency }: { cart?: Cart; currency: string }) => {
    if (!cart?.lineItems?.length)
        return {
            subtotal: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
            discount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
            tax: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
            shipping: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
            total: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
            totalAmount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 }
        };

    const currencyCode = cart.sum?.currencyCode ?? currency;
    const fractionDigits = cart.sum?.fractionDigits ?? 2;

    const totalAmount = cart.sum!.centAmount!;
    const subTotalAmount = cart.lineItems.reduce((acc, curr) => acc + (curr.price?.centAmount ?? 0) * curr.count!, 0);

    const discountedAmount =
        cart.lineItems.reduce((acc, curr) => acc + ((curr.price?.centAmount ?? 0) * curr.count! - (curr.totalPrice?.centAmount ?? 0)), 0) +
        (cart?.discountedAmount?.centAmount ?? 0);

    const totalTax = totalAmount > 0 ? (cart.taxed?.amount.centAmount ?? 0) : 0;

    const totalShipping =
        totalAmount > 0
            ? (cart.shippingInfo?.price?.centAmount ?? cart.availableShippingMethods?.[0]?.rates?.[0]?.price?.centAmount ?? 0)
            : 0;

    return {
        subtotal: {
            centAmount: subTotalAmount,
            currencyCode,
            fractionDigits
        },
        discount: {
            centAmount: discountedAmount,
            currencyCode,
            fractionDigits
        },
        shipping: {
            centAmount: totalShipping,
            currencyCode,
            fractionDigits
        },
        tax: {
            centAmount: totalTax,
            currencyCode,
            fractionDigits
        },
        total: {
            centAmount: totalAmount + totalTax,
            currencyCode,
            fractionDigits
        },
        totalAmount: {
            centAmount: totalAmount,
            currencyCode,
            fractionDigits
        }
    };
};
