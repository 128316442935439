export enum LearnerApiErrorCodesEnum {
    CIM_ERROR = 'cim_error',
    CIM_BOOKER_CONTACT_NOT_FOUND = 'cim_booker_contact_not_found',
    LEARNER_SEAT_LIMIT_EXCEEDED = 'learner_seat_limit_exceeded',
    LEARNER_SEAT_DUPLICATE_EMAILS = 'learner_seat_duplicate_emails',
    LEARNER_SEAT_DUPLICATE_EMAILS_IN_EXISTING_SEATS = 'learner_seat_duplicate_emails_in_existing_seats',
    LINE_ITEM_NOT_PROVISION_ENABLED = 'line_item_not_provision_enabled',
    CIM_CONTACT_NOT_FOUND = 'cim_contact_not_found'
}

export enum ValidationErrorCodeEnum {
    invalid_type = 'invalid_type',
    invalid_literal = 'invalid_literal',
    custom = 'custom',
    invalid_union = 'invalid_union',
    invalid_union_discriminator = 'invalid_union_discriminator',
    invalid_enum_value = 'invalid_enum_value',
    unrecognized_keys = 'unrecognized_keys',
    invalid_arguments = 'invalid_arguments',
    invalid_return_type = 'invalid_return_type',
    invalid_date = 'invalid_date',
    invalid_string = 'invalid_string',
    too_small = 'too_small',
    too_big = 'too_big',
    invalid_intersection_types = 'invalid_intersection_types',
    not_multiple_of = 'not_multiple_of',
    not_finite = 'not_finite'
}

export enum LearnerHashStatusEnum {
    OK = 'OK',
    ERROR = 'ERROR',
    NOT_FOUND = 'NOT_FOUND'
}

export enum LearnerKeyEnum {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    EMAIL = 'email'
}
