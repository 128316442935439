export enum AttributeEnum {
    SHORT_DESCRIPTION = 'shortDescription',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    ADMINISTRATE_EVENT_COHORT = 'cohort',
    SKU = 'ct.variant.sku',
    LEARNING_MODE = 'learningMode',
    EVENT_TYPE = 'eventType',
    PRICE = 'price'
}

export enum BundleMode {
    COMMENCEMENT_DATE = 'COMMENCEMENT_DATE',
    SELECT_COMPONENTS = 'SELECT_COMPONENTS',
    SELECT_GROUP = 'SELECT_GROUP'
}

export enum ProductTypeKey {
    OTHER = 'other',
    TRAINING = 'training',
    MEMBERSHIP = 'membership',
    PROFESSIONAL_SERVICE = 'professional-service',
    BUNDLE = 'bundle'
}
export enum LineItemCustomFieldsTypeEnum {
    CHECKOUT_ORDER_LINE_ITEM = 'checkout-order-line-item',
    BUNDLE_LINE_ITEM = 'bundle-line-item',
    MEMBERSHIP_LINE_ITEM = 'membership-line-item',
    TRAINING_LINE_ITEM = 'training-line-item',
    PROFESSIONAL_SERVICE_LINE_ITEM = 'professional-service-line-item',
    OTHER_LINE_ITEM = 'other-line-item'
}

export enum FilterFieldTypes {
    BOOLEAN = 'boolean',
    ENUM = 'enum',
    TEXT = 'text',
    NUMBER = 'number'
}

export interface FilterFieldValue {
    value: string;
    name?: string;
}
