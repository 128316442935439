export enum SalesLinkStatus {
    EXIST = 'exist',
    TOKEN_NOT_EXIST = 'token_not_exist',
    TOKEN_EXPIRED = 'token_expired',
    CUSTOMER_NOT_EXIST = 'customer_not_exist'
}

export enum PaymentLinkTypeEnum {
    CREDIT_CARD = 'Credit/Debit Card',
    INVOICE = 'Invoice',
    INSTALMENT = 'Instalment'
}

export enum InHouseAvailabilityReasonCodeEnums {
    PIM_DOWN = 'PIM_DOWN',
    CIM_CONTACT_NO_ACCOUNT_FOUND = 'CIM_CONTACT_NO_ACCOUNT_FOUND',
    CIM_NO_ADMINISTRATE_ACCOUNT = 'CIM_NO_ADMINISTRATE_ACCOUNT'
}
