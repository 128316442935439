import type { FC } from 'react';
import React, { useContext, createContext } from 'react';
import type { GoogleSettings } from '@wilm/common';

interface GoogleSettingsProviderProps {
    googleSettings: GoogleSettings;
    children: React.ReactNode;
}

const GoogleSettingsContext = createContext<GoogleSettings>({} as GoogleSettings);

const GoogleSettingsProvider: FC<GoogleSettingsProviderProps> = ({ googleSettings, children }) => {
    return <GoogleSettingsContext.Provider value={googleSettings}>{children}</GoogleSettingsContext.Provider>;
};

export default GoogleSettingsProvider;

export const useGoogleSettingsContext = () => useContext(GoogleSettingsContext);
