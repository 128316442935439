import React from 'react';
import type { Product } from '@wilm/common';
import { useSalesLinkDataContext } from 'providers/sales-link/data';
import ProductTile from '../tile';

const ProductResults: React.FC = () => {
    const { productsLoading, products, page, totalPages, totalResults, goToPage } = useSalesLinkDataContext();

    return (
        <>
            <h2 className="mb-10 text-18">
                {products !== undefined ? `Product results (${totalResults})` : 'Click search to find products'}
            </h2>
            <div>
                {productsLoading && <ProductTile loading product={{} as Product} />}
                {!productsLoading &&
                    products?.map(product => {
                        return <ProductTile key={product.productId} product={product} />;
                    })}
                {products?.length === 0 && !productsLoading && <div className="px-16  font-bold">No products found</div>}
            </div>
            {/* Pagination */}
            {totalPages > 1 && (
                <div className="mt-20 flex justify-center">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                            key={'page_' + (index + 1)}
                            className={`mx-4 rounded-md border border-btn-secondary-border px-8 ${page === index + 1 ? 'bg-btn-primary-bg text-white' : ''}`}
                            onClick={() => goToPage(index + 1)}
                            disabled={page === index + 1}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </>
    );
};

export default ProductResults;
