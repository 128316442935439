import { FieldType } from '../../enum/validation-rules';
import type { Fields, StringFieldDefinition } from '../../types/validation-rules';
import { FieldValidationRules } from '../../constants/validation-rules/FieldValidationRules';

export interface PayNowFields extends Fields {
    orderNumber: StringFieldDefinition;
}

export const initialPayNowConfirmationFields: Fields = {
    orderNumber: {
        name: 'orderNumber',
        type: FieldType.STRING,
        placeholder: 'A-AA-1234-5678',
        value: '',
        validation: {
            required: true,
            minLength: 2,
            maxLength: 60,
            errorMessages: {
                validation: 'error.missing.orderNumber',
                range: 'error.range.orderNumber'
            }
        }
    },
    email: {
        name: 'email',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            regex: FieldValidationRules.EMAIL.REGEX,
            minLength: FieldValidationRules.EMAIL.MIN,
            maxLength: FieldValidationRules.EMAIL.MAX,
            errorMessages: {
                validation: 'error.missing.email',
                range: 'error.range.email',
                regex: 'error.validation.email'
            }
        }
    }
};
