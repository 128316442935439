import type { FC } from 'react';
import React from 'react';
import type { MenuCategory } from '@wilm/common';
import Link from 'components/commercetools-ui/atoms/link';
import ArrowRightIcon from 'components/icons/arrow-right';
import type { Reference } from 'types/reference';
import type { ImageProps } from 'frontastic/lib/image/types';
import SocialMedia from './social-media';

export interface Props {
    links: MenuCategory[];
    hideHeaderMenu: () => void;
    socialMedia?: Social[];
    isOpen: boolean;
}

export interface Social {
    logo: ImageProps;
    reference: Reference;
}

const MobileMenu: FC<Props> = ({ links, hideHeaderMenu, socialMedia, isOpen }) => {
    return (
        <div className="mt-100 overflow-auto px-28 pb-30 md:mt-130 md:pb-70 md:pl-[7vw] md:pr-[14vw]" data-cy={isOpen ? 'menu-active' : ''}>
            <ul>
                {links?.map(link => (
                    <li key={link.categoryName} className="flex flex-col items-start md:mb-20 md:flex-row">
                        <Link
                            variant="menu-header"
                            link={link.categoryReference}
                            className={`${link?.menuSubCategories?.length ? 'mb-5' : 'mb-25'}`}
                            onClick={hideHeaderMenu}
                            data-cy="menu-link"
                        >
                            <span>{link?.categoryName}</span>
                            {link?.menuSubCategories && link?.menuSubCategories?.length > 0 && <ArrowRightIcon />}
                        </Link>
                        {link?.menuSubCategories && link?.menuSubCategories?.length > 0 && (
                            <ul className="mb-16 ml-0 md:basis-1/2">
                                {link.menuSubCategories?.map(sublink => (
                                    <li key={sublink.categoryName} className="mb-8 md:mb-12">
                                        <Link variant="menu-item" onClick={hideHeaderMenu} link={sublink.categoryReference}>
                                            {sublink?.categoryName}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
            <SocialMedia socialMedia={socialMedia} />
        </div>
    );
};

export default MobileMenu;
