import React from 'react';
import type { FieldErrors } from '@wilm/common';
import Typography from 'components/commercetools-ui/atoms/typography';
import Input from 'components/commercetools-ui/atoms/input';
import { useFormat } from 'helpers/hooks/useFormat';
import { usePaymentLinkContext } from 'providers/payment-link/payment';

interface PoNumberFieldProps {
    fieldsErrors: FieldErrors;
}

const PoNumberField: React.FC<PoNumberFieldProps> = ({ fieldsErrors }) => {
    const { poNumberField, handlePoNumberValueChange } = usePaymentLinkContext();

    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });
    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });

    const errorMessage = fieldsErrors?.[poNumberField.name]?.message;

    const formattedErrorMessage = errorMessage
        ? formatFieldErrorsMessage({
              id: errorMessage,
              defaultMessage: errorMessage
          })
        : '';

    return (
        <div className="border-checkout-border  border-b pb-16 lg:border-0 lg:pb-15">
            <Typography className="mb-16 text-20" as="h2">
                PO Number
            </Typography>

            <div>
                <Input
                    id="po-number"
                    name={poNumberField.name}
                    type="text"
                    label={formatFieldLabelsMessage({
                        id: `payment.${poNumberField.name}.label`,
                        defaultMessage: poNumberField.name
                    })}
                    value={poNumberField.value}
                    minLength={poNumberField.validation.minLength}
                    maxLength={poNumberField.validation.maxLength}
                    required={poNumberField.validation.required}
                    onChange={e => {
                        handlePoNumberValueChange(e.target.value);
                    }}
                    errorMessage={formattedErrorMessage}
                />
            </div>
        </div>
    );
};

export default PoNumberField;
