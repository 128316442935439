import React, { useCallback, useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { CartAddressType, fields } from '@wilm/common';
import type { Address as AddressType, CustomerAddressFields, Field } from '@wilm/common';
import countries from '@wilm/shared-frontend/public/countries.json';
import Typography from 'components/commercetools-ui/atoms/typography';
import SimpleAddress from 'components/sales-link/atoms/simple-address';
import { usePaymentLinkContext } from 'providers/payment-link/payment';
import AddressForm from '../../content/customer-info/address-form';

interface Props {
    address: AddressType | undefined;
    title?: string;
    wrapperClassName?: string;
    containerClassName?: string;
}

const Address: React.FC<Props> = ({ address, title, wrapperClassName, containerClassName }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { setOrderBillingAddress } = usePaymentLinkContext();

    const initialBillingFields: CustomerAddressFields = Object.keys(fields).reduce((acc, key) => {
        if (key === 'country') {
            const addressCountry = address?.country;
            const country = countries.find(country => country.value === addressCountry);
            const countryOption = country ? { label: country.label, value: country.value } : undefined;
            const option = countryOption || { label: addressCountry, value: addressCountry };
            acc[key] = {
                ...fields[key],
                value: address?.country,
                options: [option],
                getOptions: () => [option],
                disabled: true
            } as (typeof fields)[typeof key];
            return acc;
        }

        if (key === 'region') {
            acc[key] = {
                ...fields[key],
                value: address?.region,
                disabled: true
            } as (typeof fields)[typeof key];
            return acc;
        }

        acc[key] = {
            ...fields[key],
            value: address?.[key as keyof AddressType]
        } as (typeof fields)[typeof key];
        return acc;
    }, {} as CustomerAddressFields);

    const [billingFields, setBillingFields] = useState(initialBillingFields);
    const [displayBillingForm, setDisplayBillingForm] = React.useState(false);

    const handleAddressFieldChange = useCallback(
        (field: Field, value: string | boolean, addressType: CartAddressType) => {
            if (addressType === CartAddressType.BILLING) {
                if (field.name === 'country') {
                    billingFields.region.value = '';
                }
                setBillingFields({
                    ...billingFields,
                    [field.name]: {
                        ...field,
                        value
                    }
                } as CustomerAddressFields);
            }
        },
        [billingFields]
    );

    const resetAddressFields = useCallback(
        (addressType: CartAddressType) => {
            if (addressType === CartAddressType.BILLING) {
                setBillingFields(initialBillingFields);
            }
        },
        [initialBillingFields]
    );

    const handleAddressSave = async (fields: CustomerAddressFields) => {
        setIsLoading(true);
        const isNewBillingAddressSaved = await setOrderBillingAddress(fields);
        setIsLoading(false);
        if (isNewBillingAddressSaved) {
            setDisplayBillingForm(false);
            return true;
        }
        return false;
    };

    if (!address) {
        return <></>;
    }

    return (
        <div className={wrapperClassName}>
            {title && (
                <Typography className="mb-16 text-20" as="h2">
                    {title}
                </Typography>
            )}
            <div className={containerClassName}>
                <div className="flex items-start justify-between">
                    <div>{!displayBillingForm && <SimpleAddress address={address} containerClassName="font-bold" />}</div>
                    {!displayBillingForm && (
                        <button onClick={() => setDisplayBillingForm(true)} className="mt-4">
                            <PencilSquareIcon className="w-20" />
                        </button>
                    )}
                </div>
                <div
                    className={`overflow-hidden rounded-md bg-white transition-[max-height] duration-500 ease-in-out ${displayBillingForm ? 'max-h-[1200px]' : 'max-h-0'}`}
                >
                    <div className="grid gap-12 px-16 pb-30 pt-16 lg:grid-cols-3">
                        <AddressForm
                            fields={billingFields}
                            addressType={CartAddressType.BILLING}
                            handleFieldChange={handleAddressFieldChange}
                            onSave={handleAddressSave}
                            showCancel={true}
                            onCancel={() => {
                                setDisplayBillingForm(false);
                                resetAddressFields(CartAddressType.BILLING);
                            }}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Address;
