import type { FC } from 'react';
import React from 'react';
import { useParams } from 'next/navigation';
import type { Money } from '@wilm/common';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

interface PriceProps {
    price: string | number | Money;
    discountedPrice?: string | number | Money;
}

const Price: FC<PriceProps> = ({ price, discountedPrice }) => {
    const { locale } = useParams();

    return discountedPrice ? (
        <>
            <span className="pr-6 line-through">{CurrencyHelpers.formatForCurrency(price ?? 0, locale)}</span>
            <span>{CurrencyHelpers.formatForCurrency(discountedPrice ?? 0, locale)}</span>
        </>
    ) : (
        <span>{CurrencyHelpers.formatForCurrency(price ?? 0, locale)}</span>
    );
};

export default Price;
