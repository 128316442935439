import type { FC } from 'react';
import React from 'react';
import { useParams } from 'next/navigation';
import type { Money } from '@wilm/common';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

interface PriceProps {
    discountedPrice: string | number | Money;
    price: string | number | Money;
}

const Price: FC<PriceProps> = ({ discountedPrice, price }) => {
    const { locale } = useParams();
    return (
        <div className="mt-8">
            {discountedPrice ? (
                <div className="flex items-center gap-5">
                    <span className="text-base font-bold leading-loose text-minicart-promo">
                        {CurrencyHelpers.formatForCurrency(discountedPrice, locale)}
                    </span>
                    <span className="font-discounted-price text-discounted-price font-discounted-price-weight line-through">
                        {CurrencyHelpers.formatForCurrency(price ?? 0, locale)}
                    </span>
                </div>
            ) : (
                <span className="text-base font-bold leading-loose text-primary-black">
                    {CurrencyHelpers.formatForCurrency(price ?? 0, locale)}
                </span>
            )}
        </div>
    );
};

export default Price;
