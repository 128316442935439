import React from 'react';
import type { CardFields, FieldErrors } from '@wilm/common';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import Input from 'components/commercetools-ui/atoms/input';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
    paymentIsLoading: boolean;
    cardFields: CardFields;
    cardFieldsErrors: FieldErrors;
    setCardField: (field: keyof CardFields, value: string) => void;
}
const CreditCardFields: React.FC<Props> = ({ paymentIsLoading, cardFields, cardFieldsErrors, setCardField }) => {
    const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });
    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCardField(e.target.name as keyof CardFields, e.target.value);
    };

    const loader = () => {
        return (
            <>
                <Overlay />
                <div
                    style={{ width: '50px', height: '50px' }}
                    className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white"
                >
                    <div className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white">
                        <LoadingIcon className="fill-gray-700" />
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="border-checkout-border  border-b pb-16 lg:border-0 lg:pb-15">
            <Typography className="mb-16 text-20" as="h2">
                Credit / Debit card
            </Typography>
            {paymentIsLoading && loader()}

            <Input
                id={cardFields.cardHolderName.name}
                name={cardFields.cardHolderName.name}
                placeholder="First and Last name"
                type="text"
                label={formatFieldLabelsMessage({
                    id: `payment.${cardFields.cardHolderName.name}.label`,
                    defaultMessage: cardFields.cardHolderName.name
                })}
                data-cy="cs-form-holder-name"
                value={cardFields.cardHolderName.value}
                minLength={cardFields.cardHolderName.validation.minLength}
                maxLength={cardFields.cardHolderName.validation.maxLength}
                required={
                    cardFields.cardHolderName.validation.required ??
                    cardFields.cardHolderName.validation.requiredPredicate?.(cardFields) ??
                    false
                }
                onChange={handleFieldChange}
                errorMessage={
                    cardFieldsErrors.cardHolderName?.message
                        ? formatFieldErrorsMessage({ id: cardFieldsErrors.cardHolderName.message })
                        : ''
                }
            />

            <div className="mb-8 mt-16">
                <label className="text-left font-body text-14 font-label leading-loose">
                    {formatPaymentMessage({ id: 'card.number', defaultMessage: 'Card number' })}
                </label>
            </div>
            <div className="relative">
                <div
                    id="number-container"
                    data-cy="cs-form-number-container"
                    className={` ${
                        cardFieldsErrors.cardNumber?.message ? 'border-input-error' : ''
                    } h-45 w-full rounded-md border border-input-border bg-input-bg px-12 py-10 text-14 text-primary-black placeholder:text-14 placeholder:leading-normal placeholder:text-secondary-black focus:border-input-border focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-400 sm:px-8`}
                ></div>
            </div>
            {cardFieldsErrors.cardNumber?.message && (
                <p className="mt-12  text-left font-body text-sm leading-tight text-input-error">
                    {formatPaymentMessage({ id: 'card.invalid.securityNumber', defaultMessage: 'Please enter a valid value' })}
                </p>
            )}

            <div className="mt-16 gap-8 sm:flex">
                <div className="flex gap-8  sm:flex-1">
                    <div className="absolute">
                        <label htmlFor="expiryMonth" className="text-md font-label leading-loose">
                            {formatPaymentMessage({ id: 'card.exp.month', defaultMessage: 'Expiration date *' })}
                        </label>
                    </div>
                    <div className="grow sm:flex-1">
                        <Input
                            name="expiryMonth"
                            data-cy="cs-form-exp-month"
                            minLength={cardFields.expiryMonth.validation.minLength}
                            maxLength={cardFields.expiryMonth.validation.maxLength}
                            className={` ${cardFieldsErrors.expiryMonth?.message ? 'border-input-error' : ''} mt-32 text-14 placeholder:text-secondary-black sm:px-8`}
                            placeholder={formatPaymentMessage({ id: 'card.exp.month.sign', defaultMessage: 'mm' })}
                            required={
                                cardFields.expiryMonth.validation.required ??
                                cardFields.expiryMonth.validation.requiredPredicate?.(cardFields) ??
                                false
                            }
                            onChange={handleFieldChange}
                        />
                    </div>
                    <div className="pt-24" style={{ lineHeight: '54px' }}>
                        /
                    </div>
                    <div className="grow sm:flex-1">
                        <Input
                            name="expiryYear"
                            data-cy="cs-form-exp-year"
                            minLength={2}
                            maxLength={2}
                            className={` ${cardFieldsErrors.expiryYear?.message ? 'border-input-error' : ''} mt-32 text-14 placeholder:text-secondary-black  sm:px-8`}
                            placeholder={formatPaymentMessage({ id: 'card.exp.year.sign', defaultMessage: 'yy' })}
                            required={
                                cardFields.expiryMonth.validation.required ??
                                cardFields.expiryMonth.validation.requiredPredicate?.(cardFields) ??
                                false
                            }
                            onChange={handleFieldChange}
                        />
                    </div>
                </div>

                <div className="grow sm:max-w-135">
                    <div className="m-8 sm:mt-0">
                        <label className="text-left font-body text-14 font-label leading-loose">
                            {formatPaymentMessage({ id: 'card.securityNumber', defaultMessage: 'Security number' })}
                        </label>
                    </div>
                    <div
                        id="securityCode-container"
                        data-cy="cs-form-csv-container"
                        className={` ${
                            cardFieldsErrors.securityCode?.message ? 'border-input-error' : ''
                        } h-45 w-full rounded-md border border-input-border bg-input-bg px-12 py-10 text-14 text-primary-black placeholder:text-14 placeholder:leading-normal placeholder:text-secondary-black focus:border-input-border focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-400 sm:px-8`}
                    ></div>
                    {cardFieldsErrors.securityCode?.message && (
                        <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                            {formatPaymentMessage({
                                id: 'card.invalid.securityNumber',
                                defaultMessage: 'Please add a valid Security code'
                            })}
                        </p>
                    )}
                </div>
            </div>
            {(!!cardFieldsErrors.expiryMonth?.message || !!cardFieldsErrors.expiryYear?.message) && (
                <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                    {formatPaymentMessage({ id: 'card.invalid.expiration', defaultMessage: 'Please add a valid expiration date' })}
                </p>
            )}
        </div>
    );
};
export default CreditCardFields;
