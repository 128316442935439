import type { FC } from 'react';
import React from 'react';
import Input from 'components/commercetools-ui/atoms/input';
import Typography from 'components/commercetools-ui/atoms/typography';
interface QuantityProps {
    counterClassName: string;
    count: number;
    onQtyChange: (count: number) => void;
    showQuantityEdit?: boolean;
    error?: string;
}

const Quantity: FC<QuantityProps> = ({ counterClassName, count, onQtyChange, showQuantityEdit, error }) => {
    const [qty, setQty] = React.useState(count);

    function handleChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
        setQty(Number(event.target.value));
        onQtyChange(Number(event.target.value));
    }

    const incrementQty = () => {
        setQty(qty + 1);
        onQtyChange(qty + 1);
    };

    const decrementQty = () => {
        setQty(qty - 1);
        onQtyChange(qty - 1);
    };

    return (
        <>
            {/* Quantity Display */}
            <div className="mt-6">
                <p className="text-md">
                    <span> Qty: </span> <span className="font-bold">{count}</span>
                </p>
            </div>
            {/* Quantity Edit */}
            {showQuantityEdit && (
                <div className="mt-6">
                    <div className={counterClassName}>
                        <button onClick={decrementQty} className={`size-30 cursor-[inherit] `}>
                            -
                        </button>
                        <div className="w-80">
                            <Input
                                type="number"
                                className="!h-30 w-full rounded-none border-x border-y-0 border-input-border text-center text-md"
                                value={qty}
                                onChange={handleChangeEvent}
                            />
                        </div>
                        <button onClick={incrementQty} className={`size-30 cursor-[inherit]`}>
                            +
                        </button>
                    </div>
                </div>
            )}

            {/* Errors */}
            {error && (
                <Typography className="mt-12 text-sm leading-tight text-input-error" as="p">
                    {error}
                </Typography>
            )}
        </>
    );
};

export default Quantity;
