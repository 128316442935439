import type { FC } from 'react';
import { useCallback } from 'react';
import type { FieldErrors, Fields } from '@wilm/common';
import Button from 'components/commercetools-ui/atoms/button';
import FieldsForm from 'components/commercetools-ui/organisms/fields-form';
import { useFormat } from 'helpers/hooks/useFormat';
import { usePayNowOrderContext } from 'providers/pay-now/order';

const OrderNumber: FC = () => {
    const { formatMessage: formatOrderMessage } = useFormat({ name: 'order' });
    const {
        getPayNowOrderInfo,
        payNowOrderInfo,
        payNowOrderInfoLoading,
        clearPayNowOrderInfo,
        payNowConfirmationFields,
        setPayNowConfirmationFields
    } = usePayNowOrderContext();

    const onSubmit = useCallback(
        async (fields: Fields): Promise<{ isError: true; errorMessage: string; fieldErrors: FieldErrors } | { isError: false }> => {
            console.log('fields', fields);

            const payNowOrderInfo = await getPayNowOrderInfo(fields);

            if (payNowOrderInfo.isError) {
                return payNowOrderInfo;
            }

            fields.orderNumber.disabled = true;
            fields.email.disabled = true;
            setPayNowConfirmationFields(fields);

            return { isError: false };
        },
        [getPayNowOrderInfo, setPayNowConfirmationFields]
    );

    return (
        <div className="max-w-400">
            <FieldsForm
                fields={payNowConfirmationFields}
                formName="payNowConfirmationForm"
                validateOnBlur={false}
                handleFieldChange={(field, value) => {
                    setPayNowConfirmationFields({
                        ...payNowConfirmationFields,
                        [field.name]: {
                            ...field,
                            value
                        }
                    });
                }}
                onSubmit={onSubmit}
                showSubmitButton={!payNowOrderInfo}
                submitButtonText={formatOrderMessage({ id: 'proceed.to.payment', defaultMessage: 'Proceed to payment' })}
                submitButtonLoading={payNowOrderInfoLoading}
            />
            {payNowOrderInfo && (
                <Button className="mt-20 w-full" variant="secondary" onClick={clearPayNowOrderInfo}>
                    {formatOrderMessage({ id: 'change.order', defaultMessage: 'Change order' })}
                </Button>
            )}
        </div>
    );
};

export default OrderNumber;
