import type { FC } from 'react';
import React from 'react';
import type { Variant } from '@commercetools/frontend-domain-types/product';
import type { Product } from '@wilm/common';

interface BundleComponentsProps {
    variant: Variant;
    showName: boolean;
    showKey: boolean;
}

const BundleComponents: FC<BundleComponentsProps> = ({ variant, showName, showKey }) => {
    return (
        <>
            {variant.attributes?.bundleComponentsProducts.map((component: Product) => (
                <div key={`bundle_component_${component.key}`} className="mb-6">
                    {showName && <p>{component.name}</p>}
                    {showKey && <p>{component.key}</p>}
                </div>
            ))}
        </>
    );
};

export default BundleComponents;
